import axios from 'axios';
import { get } from 'ravenjs/utils/lodash';

/**
 * API to sending email for in product marketing.
 *
 * @method inProductMarketingSendEmailAPI
 * @param  {Object}  data
 * @return {Promise}
 */
export const inProductMarketingSendEmailAPI = data =>
    axios({
        method: 'post',
        url: '/tools/v1/in-product-marketing/send-email',
        data,
        type: 'apiTools',
        noBearer: true,
    });

/**
 * API to fetch upsell ads
 *
 * @method fetchUpsellAdsAPI
 * @param {Object} payload
 * @return {Promise}
 */
export const fetchUpsellAdsAPI = payload => {
    const { params } = payload;
    const platformAdCampaign = get(payload, 'platformAdCampaign', false);
    const companyNoSellItems = get(payload, 'companyNoSellItems', []);
    return axios({
        type: 'apiTools',
        method: 'get',
        params,
        url: `/tools/v1/in-product-marketing/upsell-ads?platformAdCampaign=${platformAdCampaign}&companyNoSellItems=${JSON.stringify(
            companyNoSellItems
        )}`,
    });
};

export const fetchPricingAPI = data => {
    return axios({
        method: 'post',
        data,
        url: `/v2/quotes`,
    });
};

export const fetchPaymentPageRedirectUrlAPI = data => {
    return axios({
        method: 'get',
        data,
        url: `/v2/quotes/${data}/payment-url`,
    });
};

/**
 * API to fetch upgrade ads
 *
 * @method fetchUpgradeAdsAPI
 * @param  {Object}  payload
 * @return {Promise}
 */
export const fetchUpgradeAdsAPI = payload => {
    const partnerId = get(payload, 'partnerId', null);
    const updateUrl = partnerId ? `?partnerId=${partnerId}` : '';
    return axios({
        method: 'get',
        url: `v2/partner-banners/${updateUrl}`,
    });
};

/**
 * API to post upgrade ads
 *
 * @method postUpgradeAdsAPI
 * @param  {Object}  payload
 * @return {Promise}
 */
export const postUpgradeAdsAPI = payload => {
    return axios({
        method: 'post',
        url: `v2/partner-banners/`,
        data: payload,
    });
};

/**
 *  Delete upgrade ad.
 *
 * @param {number}    adId
 * @return {Promise}
 */
export const deleteUpgradeAdAPI = adId => {
    return axios({
        method: 'delete',
        url: `v2/partner-banners/${adId}`,
    });
};

/**
 *  API to fetch upgrade ad by id.
 *
 * @param {number}    adId
 * @param {number}    partnerId
 * @return {Promise}
 */
export const getUpgradeAdByIdAPI = (adId, partnerId) => {
    const updateUrl = partnerId ? `?partnerId=${partnerId}` : '';

    return axios({
        method: 'get',
        url: `v2/partner-banners/${adId}${updateUrl}`,
    });
};

/**
 *  API to update upgrade ad by id.
 *
 * @param {Object}  payload
 * @return {Promise}
 */
export const updateUpgradeAdAPI = payload => {
    const { id } = payload;
    return axios({
        data: payload,
        method: 'put',
        url: `v2/partner-banners/${id}`,
    });
};
