import axios from 'axios';

/**
 * API call to fetch secrets by group ID
 *
 * @method fetchSecretByGroupIdAPI
 * @param  {Object} params
 * @param  {string} params.groupId - the group ID
 * @return {Promise}
 */
export function fetchSecretsByGroupIdAPI({ groupId }) {
    return axios({
        method: 'get',
        url: `/groups/${groupId}/secrets`,
        type: 'hook',
    });
}

/**
 * API call to create secret for group ID
 *
 * @method createSecretByGroupIdAPI
 * @param  {Object} params
 * @param  {string} params.groupId - the group ID
 * @param  {string} params.secret - the encryption key
 * @return {Promise}
 */
export function createSecretByGroupIdAPI({ groupId, secret }) {
    return axios({
        method: 'post',
        url: `/groups/${groupId}/secrets`,
        type: 'hook',
        data: {
            secret: {
                secret,
            },
        },
    });
}

/**
 * API call to fetch webhook rules by group ID
 *
 * @method fetchWebhookRulesAPI
 * @param  {Object} params
 * @param  {string} params.groupId - the group ID
 * @return {Promise}
 */
export function fetchWebhookRulesAPI({ groupId }) {
    return axios({
        method: 'get',
        url: `/groups/${groupId}/rules`,
        type: 'hook',
    });
}

/**
 * API call to create webhook rule by group ID
 *
 * @method createWebhookRuleAPI
 * @param  {Object} params
 * @param  {string} params.groupId - the group ID
 * @param  {string} params.callbackUrl - the callback URL
 * @return {Promise}
 */
export function createWebhookRuleAPI({ callbackUrl, groupId }) {
    return axios({
        method: 'post',
        url: `/groups/${groupId}/rules`,
        type: 'hook',
        data: {
            rule: {
                partner_id: groupId,
                enabled: true,
                title: 'Company Insights Created',
                event: {
                    type: 'webhook',
                    params: {
                        url: callbackUrl,
                    },
                },
                conditions: {
                    all: [
                        {
                            fact: 'type',
                            operator: 'equal',
                            value: 'company.insights.created',
                        },
                    ],
                },
            },
        },
    });
}

/**
 * API call to fetch webhook rules by group ID
 *
 * @method deleteWebhookRulesAPI
 * @param  {Object} params
 * @param  {string} params.groupId - the group ID
 * @return {Promise}
 */
export function deleteWebhookRulesAPI({ groupId }) {
    return axios({
        method: 'delete',
        url: `/groups/${groupId}/rules`,
        type: 'hook',
    });
}
