import { createActions } from 'utils/actions';

/**
 * Redux actions for fetching the Questionnaire information.
 *
 * @type {Object}
 */
export const fetchQuestionnaireInfo = createActions('acaReporting', 'FETCH_QUESTIONNAIRE_INFO', {
    asPromise: true,
});

/**
 * Redux actions for fetching the Questionnaire status.
 *
 * @type {Object}
 */
export const fetchQuestionnaireStatus = createActions(
    'acaReporting',
    'FETCH_QUESTIONNAIRE_STATUS',
    {
        asPromise: true,
    }
);

/**
 * Redux actions to set the Questionnaire status.
 *
 * @type {Object}
 */
export const setQuestionnaireStatus = createActions('acaReporting', 'SET_QUESTIONNAIRE_STATUS', {
    asPromise: true,
});

/**
 * Redux actions to update the Questionnaire status.
 *
 * @type {Object}
 */
export const updateQuestionnaireStatus = createActions(
    'acaReporting',
    'UPDATE_QUESTIONNAIRE_STATUS',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for fetching the Questions.
 *
 * @type {Object}
 */
export const fetchQuestions = createActions('acaReporting', 'FETCH_QUESTIONS', {
    asPromise: true,
});

/**
 * Redux actions for fetching the answer status of a Question.
 *
 * @type {Object}
 */
export const fetchAnswerList = createActions('acaReporting', 'FETCH_ANSWER_LIST', {
    asPromise: true,
});

/**
 * Redux actions for creating the answer of a Question.
 *
 * @type {Object}
 */
export const createAnswer = createActions('acaReporting', 'CREATE_ANSWER', {
    asPromise: true,
});

/**
 * Redux actions for updating the answer of a Question.
 *
 * @type {Object}
 */
export const updateAnswer = createActions('acaReporting', 'UPDATE_ANSWER', {
    asPromise: true,
});

/**
 * Redux actions to delete Answer.
 *
 * @type {Object}
 */
export const deleteAnswer = createActions('acaReporting', 'DELETE_ANSWER', {
    asPromise: true,
});

/**
 * Redux actions for fetching the answer of a Questionnaire.
 *
 * @type {Object}
 */
export const fetchAllAnswers = createActions('acaReporting', 'FETCH_ALL_ANSWERS', {
    asPromise: true,
});
