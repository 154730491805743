export const LANDING = {
    mainTitle: 'Help Center',
    introduction: '',
    path: '/help-center',
    id: 'helpCenter',
    footerText: 'Get Help',
};

export const HELP_MENU = [
    {
        title: 'Help Topics',
        path: '/help-center',
        pathToNewHelpCenter: '/help-center',
        target: '_blank',
    },
    {
        title: 'Contact Technical Support',
        path: '/my-cases/create-technical-support',
        permissionsToHide: 'platformliteHelpHide',
        target: '_self',
    },
    {
        title: 'Chat with Technical Support',
        disabledLabel: 'Loading Chat',
        id: 'vergigchat',
        path: 'chat',
        permissionsToHide: 'platformliteHelpHide',
    },
    {
        title: 'Contact an HR Expert',
        path: '/my-cases/create',
        permissions: 'askTheExpert',
        permissionsToHide: 'platformliteHelpHide',
        target: '_blank',
    },
];

export const HELP_MENU_TITLE = 'Help';
export const USER_ASSISTANCE_ENTRY = 'pageUserAssistanceEntry';
export const USER_ASSISTANCE_CUSTOM_LINK = 'userAssistanceCustomLink';
export const USER_ASSISTANCE_PENDO_WALKTHROUGH = 'userAssistancePendoWalkthrough';
export const USER_ASSISTANCE_CUSTOM_LINK_AUTHORID = 'custom link';
export const USER_ASSISTANCE_PENDO_WALKTHROUGH_AUTHORID = 'pendo link';
export const USER_ASSISTANCE_VIDEO_LINK_AUTHORID = 'video link';
export const USER_ASSISTANCE_PDF_LINK_AUTHORID = 'pdf link';
export const HELP_CATEGORIES = 'CATEGORY';
export const ABOUT = 'About';
export const ABOUT_THE = 'About the';
export const REFRESH_MESSAGE = 'Please refresh your browser window in order to start another chat.';
export const PENDO_CLASSNAME = 'pendo-user-assistance';
export const USER_ASSISTANCE = 'userassistance';
export const HELP_CENTER_FLAG = 'showHelpCenter';
export const VERGIC_OPEN_STATUS = 'open';
export const VERGIC_READY_STATUS = 'Ready';
export const VERGIC_JS =
    'https://us-content.vergic.com/B42CFCE8-3CA0-4F91-B013-86F86B98AC4F/engage.js';
export const IFRAME_SECTIONS = [
    '/training/course-catalog',
    '/training/my-courses',
    '/training/track/dashboard',
    '/hr-tools/anonymous-reporting',
    '/hr-tools/benefits-document-creator/home',
    '/hr-tools/benefits-document-creator/resources',
    '/hr-tools/benefits-document-creator/faq',
    '/hr-tools/salary-compare',
];
export const CHAT_PENDO_GUIDE = 'pendoGuideForChat';
export const TABLE_OF_CONTENTS = 'Table of Contents';
export const HELP_FAQ_LABEL = 'Feeling Lost?';
export const HELP_LANDING_LABEL = "Still can't find what you are looking for?";
export const HELP_FEATURE_LABEL = 'Need Help?';
export const BREADCRUMB_FAQ_LABEL = 'FAQs';
export const BREADCRUMB_FEATURE_LABEL = 'Feature Page';
export const GETTING_STARTED_SLUG = 'getting-started';
export const BUTTONS = {
    EXPLORE: 'Explore',
    SEARCH: 'Search',
};
export const TITLES = {
    MINERAL_PLATFORM_OVERVIEW: 'Mineral Platform Help Topics',
    FREQUENTLY_ASKED_QUESTIONS: 'Frequently Asked Questions',
    MINERAL_PLATFORM_HELP_TOPICS: 'Mineral Platform Help Topics',
    QUICK_PRODUCT_LINK: 'Need help on how to do something in Mineral?  Try the Help Center.',
};
export const DESCRIPTIONS = {
    FREQUENTLY_ASKED_QUESTIONS: 'See answers to the most frequently asked questions.',
    SEE_ALL_FAQS: 'See all FAQs',
    READ_MORE: 'Read more...',
    TYPEAHEAD: 'Search the Help Center',
    HELP_SEARCH: 'Search Help Center',
    MAX_LENGTH: 100,
    LINK_COPIED: 'Link Copied',
    COPY_LINK: 'Copy Link',
};
export const VIDEO_LANDING = {
    LINK: 'https://fast.wistia.net/embed/iframe/dy9xed8i5p',
    URL: 'https://embed-ssl.wistia.com/deliveries/bbcff7b9f36d9e7355f5f88050b648e6.jpg',
};
export const BUTTONS_LABELS = {
    START_WALKTHROUGH: 'Start Walkthrough',
    VIEW_GUIDE: 'View Guide',
    START_TOUR: 'Start Tour',
    VIEW_QUICK_REFERENCE: 'View Quick Reference',
};
export const BUTTONS_TO_PENDO = ['Start Walkthrough', 'Start Tour'];
export const TRAINING_IDS = {
    OLD_IDS: [
        '1CSehhG0K3UzMWtvZ3iSye',
        '54GwqoO1KluRmr2lDxOEk8',
        '5SowjmBIorB8DpPq9M0cs4',
        'W4EXwTexXLJH6QQzlBjdw',
        '7kU30j0PxjFwm6py4mJdJ7',
    ],
    NEW_IDS: [
        'db3d30c1-560d-a597-9798-a46e0f041244',
        '552fdf8d-0b3d-242e-6abe-e4b17c6449db',
        'f1750ed6-869c-ca63-7e6d-8293a8bb0bba',
        'cd339518-c54d-45af-003d-e82d3852a368',
        '459d105d-d6a2-b18a-35de-d1cb68167244',
    ],
};
export const VIEW_ALL_HELP_TOPICS = 'View All Help Topics';
export const RELEASE_NOTES = {
    TITLE: 'Release Notes',
    DESCRIPTION:
        "We're constantly working to improve your experience with the platform. Here’s what has changed:",
    RELEASED_ON: 'Released on',
    RESOLVED_ISSUES: 'Resolved Issues',
    KNOWN_ISSUES: 'Known Issues',
    JUMP_TO: 'Jump to Year',
    RELEASE_DATES: 'Release Dates',
};
export const HELP_CENTER_BREADCRUMB = {
    LABEL: 'Help Center',
    ID: 'helpCenter',
    PATH: '/help-center',
};
