import { APP_PERMISSIONS } from 'constants/permissions';
import { MOVE_TOOL } from 'constants/moveTool';

export const TOOL_ITEMS = [
    {
        title: 'Job Description Library',
        to: '/tools/jobs',
    },
];

/**
 * Map of app navigation menus with application routes
 *
 * @type {Object}
 */
export const APP_NAV_STATE_ROUTE_MAP = {
    resources: { to: '/resources' },
    companypolicies: { to: '/company-policies' },
    compliance: { to: '/hr-compliance' },
    myintelligencedashboard: { to: '/mineral-intelligence' },
    training: { to: '/training/course-catalog' },
    templates: { to: '/templates' },
    hrtools: { to: '/hr-tools' },
    reporting: { to: '/reporting/administration-reports' },
    ticketing: { to: '/ticketing' },
    mytickets: { to: '/my-cases' },
    safety: { to: '/safety' },
    avatar: { to: '/' },
    casecave: { to: '/case-cave' },
    community: { to: '/community', target: '_blank' },
    Marketplace: { to: '/marketplace' },
    help: { to: '/help-center' },
};

export const NAV_STATE_PARENT_ROUTE_MAP = {
    resources: '/resources',
    companypolicies: '/company-policies',
    compliance: '/hr-compliance',
    myintelligencedashboard: '/mineral-intelligence',
    training: '/training',
    templates: '/templates',
    hrtools: '/hr-tools',
    reporting: '/reporting/administration-reports',
    ticketing: '/ticketing',
    mytickets: '/my-cases',
    safety: '/safety',
    casecave: { to: '/case-cave' },
    community: { to: '/community', target: '_blank' },
    Marketplace: { to: '/marketplace' },
    help: { to: '/help-center' },
};

/**
 * Construct the nav items
 *
 * @type {Array}
 */
export const DEFAULT_NAV_ITEMS = [];

/**
 * Map of user navigation menu with application routes
 *
 * @type {Object}
 */
export const USER_NAV_STATE_ROUTE_MAP = {
    affiliates: { to: '/admin/affiliates', permission: APP_PERMISSIONS.systemAffiliatesView },
    branding: { to: '/admin/branding', permission: APP_PERMISSIONS.systemBranding },
    companies: { to: '/admin/companies', permission: APP_PERMISSIONS.systemCompaniesView },
    company: { to: '/admin/companies', permission: APP_PERMISSIONS.systemCompaniesView },
    users: { to: '/admin/users', permission: APP_PERMISSIONS.systemUsersView },
    communications: {
        to: '/admin/communication',
        permission: APP_PERMISSIONS.systemCommunicationsView,
    },
    configurations: {
        to: '/admin/configurations',
        permission: APP_PERMISSIONS.systemConfigurationsView,
    },
    dheaudits: { to: '/dhe-audits' },
    mytickets: { to: '/my-cases' },
    mytodolist: { to: '/todo' },
    myprospects: { to: '/prospect/my-prospects' },
    myprofile: { to: '/profile/details' },
    myintelligencedashboard: { to: '/mineral-intelligence/insights' },
    reporting: { to: '/reporting/administration-reports' },
    partnerenablement: { to: '/partner-enablement' },
    manageprospects: { to: '/prospect/manage-prospects' },
    manageupgrades: { to: '/futurework' },
    mapportal: { to: '/map-portal' },
    developertools: { to: '/futurework' },
    ticketingsystem: { to: '/ticketing' },
    casecave: { to: '/case-cave' },
    linkchecker: {
        to: '/admin/reporting/broken-links',
        permission: APP_PERMISSIONS.reportsBrokenLinks,
    },
    banners: { to: '/admin/banners', permission: APP_PERMISSIONS.systemBanner },
    roles: { to: '/admin/roles', permission: APP_PERMISSIONS.systemRolesView },
    skus: { to: '/admin/skus', permission: APP_PERMISSIONS.systemSkusView },
    userGuide: { to: '/help-center/company-policies/user-guides/DOCUMENT_ID/details' },
    bulktool: {
        to: '/admin/bulk-change-tool/dashboard',
        permission: APP_PERMISSIONS.bulkChangeTool,
    },
    allinsights: {
        to: '/mineral-intelligence/all-insights',
        permission: APP_PERMISSIONS.viewAllInsights,
    },
};

export const USER_NAV_TITLE_MAP = {
    companySettings: 'Company Settings',
    myTools: 'My Tools',
    partnerTools: 'Partner Tools',
    internalTools: 'Internal Tools',
};

export const USER_NAV_HANDBOOK_USER_GUIDE = {
    TITLE: 'Handbook Guide',
};

export const USER_NAV_SUPPORT_INFO = {
    TITLE: 'Need Help?',
    PAYCHEX_TITLE:
        'PEO Oasis, PEO Flex, HR Pro and HR Partner Plus clients - Please contact your Paychex HR Professional directly for any questions.',
    PAYCHEX_DESCRIPTION:
        'All other clients - please reach out to PAYCHEX_SUPPORT_EMAIL for navigational support.',
};

/**
 * Construct the user-nav items
 *
 * @type {Object}
 */
export const DEFAULT_USER_NAV_ITEMS = {
    id: 'logout',
    title: 'Sign out',
    to: '/auth/logout',
};

export const FOOTER_ITEMS = {
    PRIVACY_POLICY: {
        ID: 'privacyPolicy',
        TITLE: 'Privacy Policy',
        TO: {
            PROD: 'https://trustmineral.com/privacy-policy',
            NON_PROD: 'https://test-mineral-marketing-website.pantheonsite.io/privacy-policy/',
        },
    },
    TERMS_OF_SERVICE: {
        ID: 'termsOfService',
        TITLE: 'Terms of Service',
        TO: {
            PROD: 'https://trustmineral.com/terms-of-service',
            NON_PROD: 'https://test-mineral-marketing-website.pantheonsite.io/terms-of-service/',
        },
    },
    AI_TERMS_AND_CONDITIONS: {
        ID: 'aiTermsAndConditions',
        TITLE: 'AI Terms and Conditions',
        TO: {
            PROD: 'https://trustmineral.com/ai-terms-and-conditions',
            NON_PROD:
                'https://test-mineral-marketing-website.pantheonsite.io/ai-terms-and-conditions/',
        },
    },
    RELEASE_NOTES: {
        ID: 'releaseNote',
        TITLE: 'Release Notes',
        TO: '/futurework',
    },
    COPY_RIGHTS: {
        TITLE: '© ${currentYear} Mineral, Inc. All rights reserved',
    },
};

export const JOBS_NAV_ITEMS = [
    {
        title: 'Library',
        to: '/tools/jobs',
    },
];

/**
 * Construct the profile nav items
 *
 * @type {Array}
 */
export const MY_ACCOUNT_NAV_ITEMS = [];

/**
 * Construct the admin nav items
 *
 * @type {Array}
 */
export const ADMIN_NAV_ITEMS = [
    {
        title: 'Companies',
        to: '/admin/companies',
        permission: APP_PERMISSIONS.systemCompaniesView,
    },
    {
        title: 'Affiliates',
        to: '/admin/affiliates',
        permission: APP_PERMISSIONS.systemAffiliatesView,
    },
    {
        title: 'Users',
        to: '/admin/users',
        permission: APP_PERMISSIONS.systemUsersView,
    },
    {
        title: 'Configurations',
        to: '/admin/configurations',
        permission: APP_PERMISSIONS.systemConfigurationsView,
    },
    {
        title: 'Communication',
        to: '/admin/communication',
        permission: APP_PERMISSIONS.systemCommunicationsView,
    },
    {
        title: 'Roles',
        to: '/admin/roles',
        permission: APP_PERMISSIONS.systemRolesView,
    },
    {
        title: 'Products and Services',
        to: '/admin/skus',
        permission: APP_PERMISSIONS.systemSkusView,
    },
    {
        title: 'Branding',
        to: '/admin/branding',
        permission: APP_PERMISSIONS.systemBranding,
    },
    {
        title: 'Banners',
        to: '/admin/banners',
        permission: APP_PERMISSIONS.systemBanner,
    },
    {
        title: 'Broken Link Tool',
        to: '/admin/reporting/broken-links',
        permission: APP_PERMISSIONS.reportsBrokenLinks,
    },
];

/**
 * Construct the legal nav items
 *
 * @type {Array}
 */
export const LEGAL_NAV_ITEMS = [
    {
        title: 'Terms of Service',
        to: '/legal/terms-of-service',
    },
    {
        title: 'Privacy Policy',
        to: '/legal/privacy-policy',
    },
    {
        title: 'User Agreement',
        to: '/legal/user-agreement',
    },
    {
        title: 'Attributions',
        to: '/legal/attributions',
    },
];

/**
 * Construct the handbooks nav items
 *
 * @type {Array}
 */
export const HANDBOOKS_NAV_ITEMS = [
    {
        title: 'Library',
        to: '/company-policies/handbooks/list',
    },
    {
        title: 'Custom Policies',
        to: '/company-policies/handbooks/custom-policy',
    },
];

/**
 * Construct the ticketing system nav items
 *
 * @type {Array}
 */
export const TICKETING_SYSTEM_NAV_ITEMS = [
    {
        title: 'Dashboard',
        to: '/ticketing/dashboard',
    },
    {
        title: 'Tickets',
        to: '/ticketing/tickets',
    },
    {
        title: 'Assignments',
        to: '/ticketing/assignments',
        permission: APP_PERMISSIONS.systemTicketAssignmentsView,
    },
    {
        title: 'Unknown Tickets',
        to: '/ticketing/unknown-tickets',
        permission: APP_PERMISSIONS.systemTicketUnknownQueueView,
    },
    {
        title: 'Transfer Tickets',
        to: '/ticketing/ticket-transfer',
        permission: APP_PERMISSIONS.systemTransferTicketsView,
    },
    {
        title: 'Prepped Answers',
        to: '/ticketing/prepped-answers',
        permission: APP_PERMISSIONS.systemTicketPreppedAnswersView,
    },
    {
        title: 'VPA',
        to: '/ticketing/vpa_requests',
        permission: APP_PERMISSIONS.systemVPASuggestionView,
    },
    {
        title: 'User Lookup',
        to: '/ticketing/user-lookup',
        permission: APP_PERMISSIONS.systemTicketUserLookupView,
    },
];

/**
 * Construct the ticketing system nav items
 *
 * @type {Function}
 * @param {Object} location
 * @return {Array}
 */
export const getCaseCaveNavItems = location => [
    {
        title: 'Dashboard',
        to: '/case-cave/dashboard',
    },
    {
        title: 'Cases',
        to: '/case-cave/cases',
        permission: [
            APP_PERMISSIONS.systemCaseCaveViewOthers,
            APP_PERMISSIONS.systemCaseCaveViewOwn,
        ],
    },
    {
        title: 'Assignments',
        to: '/case-cave/assignments',
        permission: APP_PERMISSIONS.systemTicketAssignmentsView,
    },
    {
        title: 'Unknown Cases',
        to: '/case-cave/unknown-cases',
        permission: APP_PERMISSIONS.systemTicketUnknownQueueView,
    },
    {
        title: 'VPA',
        to: '/case-cave/vetted-prepped-answers',
        permission: APP_PERMISSIONS.systemVPASuggestionView,
    },
    {
        title: 'User Lookup',
        to: '/case-cave/user-lookup',
        permission: APP_PERMISSIONS.systemTicketUserLookupView,
    },
    {
        title: 'Messages',
        to: '/case-cave/messages',
        permission: APP_PERMISSIONS.systemTicketMessagesView,
    },
    {
        title: 'Meetings',
        to: '/case-cave/meetings',
        permission: APP_PERMISSIONS.systemExpertMeetingsView,
    },
    {
        title: 'Transfer Cases',
        to: '/case-cave/transfer-cases',
        permission: APP_PERMISSIONS.systemTransferTicketsView,
    },
    {
        title: 'Prepped Answers',
        to: '/case-cave/prepped-answers',
        permission: APP_PERMISSIONS.systemTicketPreppedAnswersView,
    },

    {
        title: 'PA Updates',
        to: '/case-cave/prepped-answer-updates',
        permission: APP_PERMISSIONS.systemCaseCavePASuggestUpdate,
    },
];

/**
 * Construct the Esignature nav items
 *
 * @type {Array}
 */
export const DOCUMENTS_NAV_ITEMS = [
    {
        title: 'Document Signatures',
        to: '/company-policies/documents/list',
        permission: 'esignatureView',
    },
    {
        title: 'E-Signature Management',
        to: '/company-policies/documents/management',
        permission: 'esignatureHistoryView',
    },
    {
        title: 'E-Signature Reporting',
        to: '/company-policies/documents/reporting',
        permission: 'esignatureReporting',
    },
];

export const NOTIFICATIONS_NAV_ITEMS = [
    {
        title: 'All',
        to: '/notifications/list',
    },
];

export const TOPIC_STATE_ROUTE_MAP = {
    compliance: 'Compliance Categories',
    templates: 'Templates Categories',
    resources: 'Resources Categories',
};

/**
 * Construct the osha nav items
 *
 * @type {Array}
 */
export const OSHA_NAV_ITEMS = [
    {
        title: 'OSHA 300',
        to: '/safety/osha/list',
    },
    {
        title: 'Establishment',
        to: '/safety/osha/establishments/list',
    },
];

/**
 * Construct the reports nav items
 *
 * @type {Array}
 */
export const REPORTS_NAV_ITEMS = [
    {
        title: 'Administration Reports',
        to: '/reporting/administration-reports',
        permission: APP_PERMISSIONS.reportsActiveClientsUsers,
    },
    {
        title: 'Client Usage',
        to: '/reporting/client-usage',
        permission: APP_PERMISSIONS.reportsClientUsage,
    },
    {
        title: 'Client Value Report',
        to: '/reporting/client-value-report',
        permission: APP_PERMISSIONS.reportsClientValue,
    },
    {
        title: 'Partner Value Report',
        to: '/reporting/partner-value-report',
        permission: APP_PERMISSIONS.reportsPartnerValue,
        hasMAPSkuCheck: true,
    },
    {
        title: 'Anonymous Reporting',
        to: '/reporting/anonymous-reporting',
        permission: APP_PERMISSIONS.anonymousReportingAggregateAll,
    },
    {
        title: 'Communications Report',
        to: '/reporting/communications-report',
        permission: APP_PERMISSIONS.reportsCommunications,
    },
    {
        title: 'Client Solution Report',
        to: '/reporting/client-solution-report',
        permission: APP_PERMISSIONS.clientSolution,
        hasLicenseCheck: true,
    },
];

export const SUBNAV_TITLE = {
    reporting: 'Reporting',
};

export const NAV_STATE_NOT_BE_CLICKABLE = [];

export const SPECIAL_SECTION_NEED_BACK = [
    '/hr-tools/job-description-builder/edit/',
    '/hr-tools/aca-reporting/info',
    '/hr-tools/aca-reporting/questionnaire',
];

export const SOFT_LOGIN_APP_NAVIGATION = {
    items: [
        {
            title: 'HR Compliance',
            state: 'compliance',
            to: '/hr-compliance',
        },
        {
            title: 'Company Policies',
            state: 'companypolicies',
            to: '/company-policies',
        },
        {
            title: 'Safety',
            state: 'safety',
            to: '/safety',
        },
        {
            title: 'Training',
            state: 'training',
            to: '/training/course-catalog',
        },
        {
            title: 'HR Tools',
            state: 'hrtools',
            to: '/hr-tools',
        },
        {
            title: 'Templates',
            state: 'templates',
            to: '/templates',
        },
        {
            title: 'Resources',
            state: 'resources',
            to: '/resources',
        },
    ],
    logoUrl: '/',
    show: true,
};

export const IMPERSONATION_RESTRICTED_NAV_ITEMS = [];

export const IMPERSONATION_RESTRICTED_SUB_NAV_ITEMS = [
    '/hr-tools/anonymous-reporting',
    '/reporting/anonymous-reporting',
];

/**
 * Construct the Bulk Changes nav items
 *
 * @type {Array}
 */
export const BULKCHANGES_NAV_ITEMS = [
    {
        title: 'Dashboard',
        to: `${MOVE_TOOL.PATH}dashboard`,
    },
    {
        title: 'Merge',
        to: `${MOVE_TOOL.PATH}merge`,
    },
    {
        title: 'Move',
        to: `${MOVE_TOOL.PATH}move`,
    },
    {
        title: 'Mass Data Update',
        to: `${MOVE_TOOL.PATH}mass-data-update`,
    },
    {
        title: 'Send Mass Welcome Email',
        to: `${MOVE_TOOL.PATH}mass-welcome`,
    },
];

// // TODO: Intelligence readiness remove the below constants
/**
 * Construct the Mineral Intelligence nav items
 *
 * @type {Array}
 */
export const MINERAL_INTELLIGENCE_NAV_ITEMS = [
    {
        title: 'HR Compliance Insights',
        state: 'myintelligencedashboard',
        to: '/mineral-intelligence/insights',
    },
];

/**
 * Construct the Mineral Intelligence nav items
 *
 * @type {Array}
 */
export const MINERAL_INTELLIGENCE_NAV_ITEMS_FEATUREFLAG = [
    // TODO: Intelligence readiness remove feature flag
    {
        title: 'HR Compliance Dashboard',
        state: 'myintelligencedashboard',
        padding: '0',
        margin: '0 15px 0 0',
        fontWeight: 'bold',
        to: '/mineral-intelligence/dashboard',
    },
    {
        title: 'HR Compliance Insights',
        state: 'myinsights',
        to: '/mineral-intelligence/insights',
        padding: '0',
        fontWeight: 'bold',
    },
];

export const EXTERNAL_ROUTES = ['/community'];
