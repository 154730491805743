import { call, put } from 'redux-saga/effects';
import _get from 'lodash/get';

import {
    createAnswerAPI,
    deleteAnswerAPI,
    fetchAllAnswersAPI,
    fetchAnswerListAPI,
    fetchQuestionnaireInfoAPI,
    fetchQuestionnaireStatusAPI,
    fetchQuestionsAPI,
    setQuestionnaireStatusAPI,
    updateAnswerAPI,
    updateQuestionnaireStatusAPI,
} from 'modules/apis';

import * as acaActions from '../action';

/**
 * Saga to fetch QuestionnaireInfo
 *
 * @param   {Object}    action
 */
export function* fetchQuestionnaireInfoSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchQuestionnaireInfoAPI, { payload });
        const data = _get(response, 'data', {});
        yield put(acaActions.fetchQuestionnaireInfo.success(data));
    } catch (e) {
        yield put(acaActions.fetchQuestionnaireInfo.error(e));
    }
}

/**
 * Saga to fetch Questionnaire Status
 *
 * @param   {Object}    action
 */
export function* fetchQuestionnaireStatusSaga(action) {
    try {
        const {
            payload: { id, companyId },
        } = action;
        const response = yield call(fetchQuestionnaireStatusAPI, { id, companyId });
        const data = _get(response, 'data', {});
        yield put(acaActions.fetchQuestionnaireStatus.success(data));
    } catch (e) {
        yield put(acaActions.fetchQuestionnaireStatus.error(e));
    }
}

/**
 * Saga to set Questionnaire Status
 *
 * @param   {Object}    action
 */
export function* setQuestionnaireStatusSaga(action) {
    try {
        const {
            payload: { questionnaireId, data },
        } = action;
        const response = yield call(setQuestionnaireStatusAPI, { questionnaireId, data });
        yield put(acaActions.setQuestionnaireStatus.success(response));
    } catch (e) {
        yield put(acaActions.setQuestionnaireStatus.error(e));
    }
}

/**
 * Saga to update Questionnaire Status
 *
 * @param   {Object}    action
 */
export function* updateQuestionnaireStatusSaga(action) {
    try {
        const {
            payload: { questionnaireId, data, statusId },
        } = action;
        const response = yield call(updateQuestionnaireStatusAPI, {
            questionnaireId,
            data,
            statusId,
        });
        yield put(acaActions.updateQuestionnaireStatus.success(response));
    } catch (e) {
        yield put(acaActions.updateQuestionnaireStatus.error(e));
    }
}

/**
 * Saga to fetch all ACA reporting Questions
 *
 * @param   {Object}    action
 */
export function* fetchQuestionsSaga(action) {
    try {
        const {
            payload: { questionnaireId },
        } = action;
        const response = yield call(fetchQuestionsAPI, { questionnaireId });
        const data = _get(response, 'data', {});
        yield put(acaActions.fetchQuestions.success(data));
    } catch (e) {
        yield put(acaActions.fetchQuestions.error(e));
    }
}

/**
 * Saga to fetch answer status to a Question
 *
 * @param   {Object}    action
 */
export function* fetchAnswerListSaga(action) {
    try {
        const {
            payload: { questionnaireId, questionId, id },
        } = action;
        const response = yield call(fetchAnswerListAPI, { questionnaireId, questionId, id });
        const data = _get(response, 'data', {});
        yield put(acaActions.fetchAnswerList.success(data));
    } catch (e) {
        yield put(acaActions.fetchAnswerList.error(e));
    }
}

/**
 * Saga to create answer to a Question
 *
 * @param   {Object}    action
 */
export function* createAnswerSaga(action) {
    try {
        const {
            payload: { questionnaireId, questionId, data },
        } = action;
        const response = yield call(createAnswerAPI, { questionnaireId, questionId, data });
        yield put(acaActions.createAnswer.success(response));
    } catch (e) {
        yield put(acaActions.createAnswer.error(e));
    }
}

/**
 * Saga to update answer to a Question
 *
 * @param   {Object}    action
 */
export function* updateAnswerSaga(action) {
    try {
        const {
            payload: { questionnaireId, questionId, data, id },
        } = action;
        const response = yield call(updateAnswerAPI, { questionnaireId, questionId, data, id });
        yield put(acaActions.updateAnswer.success(response));
    } catch (e) {
        yield put(acaActions.updateAnswer.error(e));
    }
}

/**
 * Saga to delete answer api
 *
 * @param   {Object}    action
 */
export function* deleteAnswerSaga(action) {
    try {
        const {
            payload: { questionnaireId, questionId, answerId },
        } = action;
        const response = yield call(deleteAnswerAPI, { questionnaireId, questionId, answerId });
        yield put(acaActions.deleteAnswer.success(response));
    } catch (e) {
        yield put(acaActions.deleteAnswer.error(e));
    }
}

/**
 * Saga to fetch all answers of a questionnaire.
 *
 * @param   {Object}    action
 */
export function* fetchAllAnswersSaga(action) {
    try {
        const {
            payload: { questionnaireId, companyId },
        } = action;
        const response = yield call(fetchAllAnswersAPI, { questionnaireId, companyId });
        const data = _get(response, 'data', {});
        yield put(acaActions.fetchAllAnswers.success(data));
    } catch (e) {
        yield put(acaActions.fetchAllAnswers.error(e));
    }
}
