export const DEFAULT_COLORS = {
    RED: '#B53349',
    LINK: '#3052DA',
    LINK_LIGHT: '#90B2FF',
    LINK_PAYCHEX: '#0052CC',
    BLUE: '#9CDEFF',
    BLUE_1: '#3350D1',
    BLUE_2: '#4560D5',
    BLUE_3: '#2b60e7',
    BLUE_4: '#6EA9E4',
    BLUE_5: '#114E85',
    ORANGE: '#F15A26',
    FAVORITE_ORANGE: '#FF5A1B',
    YELLOW: '#FAC03B',
    YELLOW_HIGHLIGHT: '#FFE966',
    TAN: '#EFE3D9',
    BLACK_00: '#000000',
    BLACK: '#282828',
    BLACK_2: '#080808',
    BLACK_3: '#3A3A3A',
    PEACH: '#FFAD76',
    STEEL: '#647785',
    GREEN: '#35A66C',
    GREEN_1: '#006666',
    GREEN_2: '#F0FFDF',
    GREEN_3: '#288054',
    PLACEHOLDER: '#7E7E7E',
    DARKEST: '#5E5E5E',
    DARK: '#A4A4A4',
    LIGHT: '#C7C7C7',
    LIGHT1: '#A7A7A7',
    LIGHTLY: '#CBCBCB',
    LIGHTEST: '#F4F4F4',
    LIGHTER: '#E6E6E6',
    LIGHTBLUE: '#CEEFFF',
    LIGHTTAN: '#FAF6F3',
    LIGHTPINK: '#FFA9B9',
    LIGHTPINK_2: '#EFE3D9ED',
    WHITE: '#FFFFFF',
    WHITE_80: 'rgba(255, 255, 255, 0.8)',
    HANDBOOK_MULTISTATE: '#F5A623',
    HANDBOOK_FEDERAL: '#1A91EB',
    HANDBOOK_ONESTATE: '#7ED321',
    SEMANTIC_HIGHLIGHT: 'rgba(156, 222, 255, 0.5)',
    SEMANTIC_NO_EDIT: 'rgba(250, 192, 59, 0.38)',
    SEMANTIC_LIGHT_ORANGE: 'rgba(241, 90, 38, 0.15)',
    SEMANTIC_RED_ERROR: 'rgba(181, 51, 73, 0.36)',
    SEMANTIC_RED_ERROR15: 'rgba(181, 51, 73, 0.15)',
    SEMANTIC_RED_ERROR10: 'rgba(181, 51, 73, 0.10)',
    SEMANTIC_LIGHT_GREY: 'rgba(230, 230, 230, 0.25)',
    SEMANTIC_NEW_HIGHLIGHT: 'rgba(53, 166, 108, 0.6)',
    BADGE_BLUE: 'rgba(48, 82, 218, 0.5)',
    BADGE_BLUE25: 'rgba(48, 82, 218, 0.25)',
    BADGE_BLUE15: 'rgba(48, 82, 218, 0.15)',
    BADGE_BROWN: 'rgba(161, 108, 47, 0.5)',
    BADGE_BROWN25: 'rgba(161, 108, 47, 0.25)',
    BADGE_GREEN: 'rgba(53, 166, 108, 0.5)',
    BADGE_LIGHT_BLUE: 'rgba(156, 222, 255, 0.6)',
    BADGE_ORANGE: 'rgba(241, 90, 38, 0.5)',
    BADGE_ORANGE25: 'rgba(241, 90, 38, 0.25)',
    BADGE_ORANGE15: 'rgba(241, 90, 38, 0.15)',
    BADGE_PINK: 'rgba(255, 169, 185, 0.5)',
    BADGE_VIOLET: 'rgba(146, 94, 199, 0.5)',
    BADGE_VIOLET25: 'rgba(146, 94, 199, 0.25)',
    BADGE_VIOLET15: 'rgba(146, 94, 199, 0.15)',
    BADGE_YELLOW: 'rgba(250, 192, 59, 0.5)',
    BADGE_YELLOW25: 'rgba(250, 192, 59, 0.25)',
    BADGE_DARK_PINK: 'rgba(253, 167, 255, 0.8)',
    BADGE_DARK_PINK50: 'rgba(253, 167, 255, 0.5)',
    BADGE_DARK_PINK25: 'rgba(253, 167, 255, 0.25)',
    BADGE_PRISM_GREEN25: 'rgba(14, 98, 7, 0.25)',
    BADGE_PRISM_ORANGE25: 'rgba(239, 108, 0, 0.25)',
    CATEGORY_BENEFITS: '#E88B00',
    CATEGORY_DISCRIMINATION_EQUAL_EMPLOYMENT: '#3B9DCE',
    CATEGORY_LEAVES_ACCOMMODATIONS: '#925EC7',
    PALE_PURPLE: 'rgba(146, 94, 199, 0.4)',
    CATEGORY_MANAGING_EMPLOYEES: '#2F7152',
    CATEGORY_WAGES_HOURS: '#915F43',
    CATEGORY_TERMINATION: '#7D197D',
    CATEGORY_HIRING: '#B54124',
    CATEGORY_SAFETY_HEALTH: '#1D1DC2',
    TABLE_GREY: '#FAFAFA',
    TABLE_TITLE_ROW: '#F1F1F1',
    COMPONENTS_BOX_1: '#F8F1EB',
    COMPONENTS_BOX_2: '#F8F8F8',
    COMPONENTS_BLUE_15: 'rgba(156, 222, 255, 0.15)',
    COMPONENTS_DISABLED: '#E1E5E9',
    COMPONENTS_TAN_30: 'rgba(239, 227, 217, 0.3)',
    COMPONENTS_THE_GREY_STYLE: '#F9F9F9',
    COMPONENTS_TAN_50: 'rgba(239, 227, 217, 0.5)',
    COMPONENTS_TAN_2: '#F4EEEA',
    COMPONENTS_BLUE_50: 'rgba(156, 222, 255, 0.5)',
    COMPONENTS_THE_GREY_STYLE_50: 'rgba(249, 249, 249, 0.5)',
    COMPONENTS_TABLE_TITLE_ROW: '#F5F5F5',
    COMPONENTS_BLUE_50_2: 'rgba(156, 222, 255, 0.5)',
    COMPONENTS_MODAL_OVERLAY_BLACK: 'rgba(100, 119, 133, 0.71)',
    BRAND_RED_2: '#DBA1AB',
    BRAND_BLUE_2: '#D0EDFC',
    BRAND_PEACH_2: '#FCD7BF',
    BRAND_YELLOW_2: '#FAE0A4',
    BRAND_GREEN_2: '#A2D4BA',
    BANNER_RED: '#DA99A4',
    BANNER_PEACH: '#F8C4B3',
    TAB_GREY: '#F3F3F3',
    ACTIONS_TAN: '#F7F1EC',
    GREY: '#B9B9B9',
    GREY_2: '#ABABAB',
    GREY_3: '#999999',
    GREY_4: '#666666',
    GREY_5: '#979797',
    GREY_6: '#F2F3F6',
    GREY_61: 'rgba(40, 40, 40, 0.16)',
    GREY_8: '#424242',
    GREY_9: '#E0E4E7',
    GREY_10: '#7E7E7E',
    GREY_11: 'rgba(40, 40, 40, 0.12)',
    GREY_12: 'rgba(40, 40, 40, 0.08)',
    GREY_13: 'rgba(40, 40, 40, 0.2)',
    GREY_16: '#A9A9A9',
    GREY_17: 'rgba(0, 0, 0, 0.16)',
    GREY_18: '#939598',
    GREY_19: '#848484',
    GREY_20: '#808285',
    GREY_21: '#E6E7E8',
    GREY_22: '#BCBEC0',
    GREY_D4: '#D4D4D4',
    GREY_23: '#58595B',
    GREY_24: '#BCBEC0',
    GREY_25: '#A7A7A7',
    GREY_27: '#6D6E71',
    RED_2: '#E62C14',
    RED_3: '#DC3349',
    ALERT: '#fffcea',
    LOGIN_ERROR_BACKGROUND: '#FCE9E7',
    INPUT_ICON_ERROR: '#216BA5',
    GREY_14: '#696969',
    GREY_15: '#E6EAEE',
    BROWN: '#a16c2f',
    DARK_GREY: '#535353',
    LIGHTPURPLE: 'rgba(146, 94, 199, 0.1)',
    LIGHTORANGE: 'rgba(181, 65, 36, 0.1)',
    LIGHTBLUE1: 'rgba(206, 239, 255, 0.5)',
    LIGHTBLUE2: '#3A9DCE',
    ORANGE_LIGHT: '#F1C257',
    LIGHT_BANNER: '#F7F6F1',
    NAVY_BLUE: '#0D1D38',
    GREY_PRISM_DESC: '#00000099',
    PRISM_GREEN: '#0E6207',
    PRISM_ORANGE: '#EF6C00',
    RED_4: '#B91C1C',
    GREEN_4: '#127E09',
    ORANGE_2: '#F57C00',
    GREY_26: '#0d1d3814',
    CHAT_BRAND_BASE: '#1232AF',
    CHAT_AVATAR_WHITE: '#FDFDFD',
    CHAT_AVATAR_BASE: '#0067D2',
    ORANGE_3: '#F57F17',
    PROGRESS_BAR: '#429EBD',
    PROGRESS_BAR_BG: '#E0E0E0',
};
