import { call, put } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import * as uiActions from 'modules/ui/actions';
import {
    fetchEsignatureUserDocumentsAPI,
    downloadDocumentForUserAPI,
    fetchSignatureAPI,
    fetchESignedDocumentsAPI,
    fetchEmbeddedHelloSignUrlAPI,
    postDocumentForSignaturesAPI,
    cancelAllEsignatureRequestAPI,
    downloadDocumentFromHelloSignSagaAPI,
    fetchSignDocumentDetailsAPI,
    saveUploadDocumentInfoAPI,
    uploadDocumentForSignaturesAPI,
    cancelIndividualEsignatureAPI,
    remindEsignatureRequestAPI,
    fetchDocumentStatesAPI,
    updateTranslationAPI,
    fetchDocumentEsignatureDetailAPI,
} from 'modules/apis/esigDocuments';
import * as esigDocuments from '../actions';

/**
 * The saga for fetching the list of Esignature document list.
 *
 * @method fetchEsignatureUserDocumentsSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchEsignatureUserDocumentsSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING DOCUMENTS'));
        const { payload } = action;
        const resp = yield call(fetchEsignatureUserDocumentsAPI, payload);
        const esignatures = get(resp, 'data', {});
        yield put(esigDocuments.fetchEsignatureUserDocuments.success(esignatures));
        return resp;
    } catch (error) {
        yield put(esigDocuments.fetchEsignatureUserDocuments.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for downloading the esignature document
 *
 * @method downloadDocumentForUserSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* downloadDocumentForUserSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'DOWNLOADING DOCUMENT'));
        const { payload } = action;
        const resp = yield call(downloadDocumentForUserAPI, payload);
        const data = get(resp, 'data', {});
        yield put(esigDocuments.downloadDocumentForUser.success(data));
        return resp;
    } catch (error) {
        yield put(esigDocuments.downloadDocumentForUser.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for fetching the list of Esignature document list.
 *
 * @method fetchSignatureSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchSignatureSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(fetchSignatureAPI, payload);
        const data = get(resp, 'data', {});
        yield put(esigDocuments.fetchSignature.success(data));
        return resp;
    } catch (error) {
        yield put(esigDocuments.fetchSignature.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for fetching the list of Esigned document list.
 *
 * @method fetchESignedDocumentsSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchESignedDocumentsSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING DOCUMENTS'));
        const { payload } = action;
        const resp = yield call(fetchESignedDocumentsAPI, payload);
        const signDocuments = get(resp, 'data', {});
        yield put(esigDocuments.fetchESignedDocuments.success(signDocuments));
        return resp;
    } catch (error) {
        yield put(esigDocuments.fetchESignedDocuments.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for fetching the Embedded Hello Sign.
 *
 * @method fetchEmbeddedHelloSignUrlSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchEmbeddedHelloSignUrlSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING'));
        const { payload } = action;
        const resp = yield call(fetchEmbeddedHelloSignUrlAPI, payload);
        const data = get(resp, 'data', {});
        yield put(esigDocuments.fetchEmbeddedHelloSignUrl.success(data));
        return resp;
    } catch (error) {
        yield put(esigDocuments.fetchEmbeddedHelloSignUrl.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for submitting document for esignature.
 *
 * @method postDocumentForSignaturesSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* postDocumentForSignaturesSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING'));
        const {
            payload: {
                data,
                queryParams: { sendToAll, signatureDocumentId },
            },
        } = action;
        const resp = yield call(postDocumentForSignaturesAPI, data, sendToAll, signatureDocumentId);
        yield put(esigDocuments.postDocumentForSignatures.success(resp));
        return resp;
    } catch (error) {
        yield put(esigDocuments.postDocumentForSignatures.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for cancelling the esignature for a document.
 *
 * @method cancelAllEsignatureRequestSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* cancelAllEsignatureRequestSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING'));
        const { payload } = action;
        const resp = yield call(cancelAllEsignatureRequestAPI, payload);
        const data = get(resp, 'data', {});
        yield put(esigDocuments.cancelAllEsignatureRequest.success(data));
        return resp;
    } catch (error) {
        yield put(esigDocuments.cancelAllEsignatureRequest.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for downloading the esignature for a document.
 *
 * @method downloadDocumentFromHelloSignSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* downloadDocumentFromHelloSignSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING'));
        const { payload } = action;
        const resp = yield call(downloadDocumentFromHelloSignSagaAPI, payload);
        const data = get(resp, 'data', {});
        yield put(esigDocuments.downloadDocumentFromHelloSign.success(data));
        return resp;
    } catch (error) {
        yield put(esigDocuments.downloadDocumentFromHelloSign.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for fetching the sign document details.
 *
 * @method fetchSignDocumentDetailsSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchSignDocumentDetailsSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING'));

        if (action.payload.sort === '+status' || action.payload.sort === '-status') {
            action.payload.sort += ',+signerName';
        }

        const { payload } = action;
        const resp = yield call(fetchSignDocumentDetailsAPI, payload);
        const signDocuments = get(resp, 'data', {});
        yield put(esigDocuments.fetchSignDocumentDetails.success(signDocuments));
        return resp;
    } catch (error) {
        yield put(esigDocuments.fetchSignDocumentDetails.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for saving the uploaded document.
 *
 * @method saveUploadDocumentInfoSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* saveUploadDocumentInfoSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING'));
        const { payload } = action;
        const resp = yield call(saveUploadDocumentInfoAPI, payload);
        const data = get(resp, 'data', {});
        yield put(esigDocuments.saveUploadDocumentInfo.success(data));
        return resp;
    } catch (error) {
        yield put(esigDocuments.saveUploadDocumentInfo.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for submitting the request for uploaded document
 *
 * @method uploadDocumentForSignaturesSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* uploadDocumentForSignaturesSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING'));
        const { payload } = action;
        const resp = yield call(uploadDocumentForSignaturesAPI, payload);
        const data = get(resp, 'data', {});
        yield put(esigDocuments.uploadDocumentForSignatures.success(data));
        return resp;
    } catch (error) {
        yield put(esigDocuments.uploadDocumentForSignatures.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for requesting cancellation for individual esignatures
 *
 * @method cancelIndividualEsignatureSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* cancelIndividualEsignatureSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING'));
        const { payload } = action;
        const resp = yield call(cancelIndividualEsignatureAPI, payload);
        const data = get(resp, 'data', {});
        yield put(esigDocuments.cancelIndividualEsignature.success(data));
        return resp;
    } catch (error) {
        yield put(esigDocuments.cancelIndividualEsignature.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for sending a request for reminder to esignatures.
 *
 * @method remindEsignatureRequestSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* remindEsignatureRequestSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'LOADING'));
        const { payload } = action;
        const resp = yield call(remindEsignatureRequestAPI, payload);
        const data = get(resp, 'data', {});
        yield put(esigDocuments.remindEsignatureRequest.success(data));
        return resp;
    } catch (error) {
        yield put(esigDocuments.remindEsignatureRequest.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for fetching the sign document details.
 *
 * @method fetchDocumentStatesSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchDocumentStatesSaga(action) {
    try {
        const {
            payload: { documentId },
        } = action;
        const resp = yield call(fetchDocumentStatesAPI, documentId);
        const signDocuments = get(resp, 'data', {});
        yield put(esigDocuments.fetchDocumentStates.success(signDocuments));
        return resp;
    } catch (error) {
        yield put(esigDocuments.fetchDocumentStates.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for fetching the sign document details.
 *
 * @method updateTranslationSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* updateTranslationSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(updateTranslationAPI, payload);
        const noteTranslated = get(resp, 'data', {});
        yield put(esigDocuments.updateTranslation.success(noteTranslated));
        return resp;
    } catch (error) {
        yield put(esigDocuments.updateTranslation.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for fetching the sign document details.
 *
 * @method fetchDocumentEsignatureDetailSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* fetchDocumentEsignatureDetailSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(fetchDocumentEsignatureDetailAPI, payload);
        const signDocuments = get(resp, 'data', {});
        yield put(esigDocuments.fetchDocumentEsignatureDetail.success(signDocuments));
        return resp;
    } catch (error) {
        yield put(esigDocuments.fetchDocumentEsignatureDetail.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}
