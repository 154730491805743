import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { call, select, put } from 'redux-saga/effects';
import { TYPE_SEND_EMAIL } from 'constants/ai';
import * as userSelectors from 'modules/user/reducer';
import { closeAISessionSaga, sendEmailCloseAISessionSaga } from 'modules/aiAssistant/sagas';
import { getChatSessionId } from 'utils/common';
import * as authActions from '../actions';

export function* closeAriesSessionSaga(action) {
    try {
        const reload = _get(action, 'payload.reload', false);
        const chatSessionId = getChatSessionId();
        if (!_isEmpty(chatSessionId)) {
            const userId = yield select(userSelectors.getUserId);
            const companyId = yield select(userSelectors.getCompanyId);
            const params = {
                type: TYPE_SEND_EMAIL,
                companyId,
                additionalInfo: {
                    chatSessionId,
                },
                userIds: [userId],
            };
            yield call(closeAISessionSaga, { payload: { params: { chatSessionId } } });
            yield call(sendEmailCloseAISessionSaga, { payload: { params } });
            localStorage.removeItem('ai_chat_session_active');
        }

        if (!_isEmpty(JSON.parse(localStorage.getItem('ai_chat_session')))) {
            localStorage.removeItem('ai_chat_session');
        }
        if (reload) {
            window.location.reload();
        }
        yield put(authActions.closeAriesSession.success());
    } catch (e) {
        yield put(authActions.closeAriesSession.error(e));
    }
}
