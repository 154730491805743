import ANONYMOUS_REPORTING from './anonymousReporting';
import ASSESSMENT_TOOL from './assessmentTool';
import COMPLIANCE from './compliance';
import CONTENT_DETAIL from './contentDetail';
import CONTENT_LIST from './contentList';
import DASHBOARD from './dashboard';
import FITNESS_TEST from './fitnessTest';
import HELP_CENTER from './helpCenter';
import HELP_ICON from './helpIcon';
import JDB from './jdb';
import NAVIGATION from './navigation';
import NOTIFICATION from './notification';
import PARTNER_ENABLEMENT from './partnerEnablement';
import RESOURCES from './resources';
import SEARCH from './search';
import INTELLIGENCE from './intelligence';
import TODOLIST from './todolist';
import PROFILE from './profile';
import HANDBOOK from './handbook';
import CALCULATORS from './calculators';
import SAFETY from './safety';
import CASE_CAVE from './casecave';
import MEETINGS from './meetings';
import COMMON_ERROR from './commonError';
import AI_ASSISTANT from './aiAssistant';
import IN_PLATFORM_UPGRADE_ADS from './inPlatformUpgradeAd';
import HR_LOGICS from './hrLogics';

const EVENTS = {
    ANONYMOUS_REPORTING,
    ASSESSMENT_TOOL,
    COMPLIANCE,
    CONTENT_DETAIL,
    CONTENT_LIST,
    DASHBOARD,
    FITNESS_TEST,
    HELP_CENTER,
    HELP_ICON,
    JDB,
    NAVIGATION,
    PARTNER_ENABLEMENT,
    NOTIFICATION,
    RESOURCES,
    SEARCH,
    INTELLIGENCE,
    TODOLIST,
    PROFILE,
    HANDBOOK,
    CALCULATORS,
    SAFETY,
    CASE_CAVE,
    MEETINGS,
    COMMON_ERROR,
    AI_ASSISTANT,
    IN_PLATFORM_UPGRADE_ADS,
    HR_LOGICS,
};

export default EVENTS;
