import React from 'react';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import _replace from 'lodash/replace';
import queryString from 'query-string';
import styled from 'styled-components';

import MenuLink from 'ravenjs/lib/MenuLink';
import MenuItem from 'ravenjs/lib/MenuItem';
import Menu from 'ravenjs/lib/Menu';
import Typography from 'ravenjs/lib/Typography';
import Tooltip from 'ravenjs/lib/Tooltip';
import { get, isEmpty } from 'ravenjs/utils/lodash';
import { renderCheck } from 'ravenjs/utils/viewport';

import { APP_NAV_STATE_ROUTE_MAP, USER_NAV_STATE_ROUTE_MAP } from 'constants/navigation';
import { APP_PERMISSIONS } from 'constants/permissions';
import { SAFETY_MANUAL_TEMPLATE_ID } from 'constants/safety';
import { getAllFilters } from 'utils/filters';

export const formatAppNavItems = menus => {
    return menus
        .filter(({ state }) => state !== 'avatar' && APP_NAV_STATE_ROUTE_MAP.hasOwnProperty(state))
        .map(({ label, state, subnav }) => ({
            allowedroles: get(APP_NAV_STATE_ROUTE_MAP[state], 'allowedroles'),
            title: label,
            state,
            to: get(APP_NAV_STATE_ROUTE_MAP[state], 'to', '/futurework'),
            subnav,
        }));
};

export const formatSubNavItems = (items, state) => {
    switch (state) {
        case 'compliance':
            return getComplianceSubNavMainItems(items);
        case 'myintelligencedashboard':
            return getIComplianceSubNavMainItemsWithIntelligence(items);
        case 'templates':
            return getFormsAndLettersSubNavMainItems(items);
        case 'hrtools':
            return getHRToolsSubNavMainItems(items);
        case 'resources':
            return getFundamentalSubNavMainItems(items);
        case 'reporting':
            return getReportingSubNavMainItems(items);
        case 'safety':
        case 'safetyehs':
            return getSafetySubNavMainItems(items);
        case 'ticketing':
            return getTicketingSubNavItems(items);
        case 'training':
            return getLearnSubNavItems(items);
        case 'help':
            return getHelpCenterSubNavItems(items);
        default:
            return items;
    }
};

export const formatUserNavItems = menus => {
    const avatar = menus.find(({ state }) => state === 'avatar');
    if (!avatar) {
        return {};
    }
    const userNav = {};
    const navItems = get(avatar, 'subnav', {});
    const subSections = Object.keys(navItems);
    subSections.forEach(section => {
        userNav[section] = navItems[section].map(({ label, state }) => {
            const userNavState = USER_NAV_STATE_ROUTE_MAP[state] || {};
            return {
                permission: get(userNavState, 'permission'),
                title: label,
                state,
                to: get(userNavState, 'to', '/futurework'),
            };
        });
    });
    return userNav;
};

export const getComplianceSubNavMainItems = items => {
    items.map(item => {
        switch (item.state) {
            case 'law':
                item.to = '/hr-compliance/laws';
                break;
            case 'lawalerts':
                item.to = '/hr-compliance/law-alerts';
                break;
            case 'hrfitnesstest':
                item.to = '/hr-compliance/hr-assessment/start';
                break;
            case 'myintelligencedashboard':
                item.to = '/mineral-intelligence';
                break;
            case 'myinsights':
                item.to = '/mineral-intelligence/insights';
                break;
            default:
                item.to = '/futurework';
                break;
        }

        return item;
    });

    return items;
};

export const getIComplianceSubNavMainItemsWithIntelligence = items => {
    items.map(item => {
        switch (item.state) {
            case 'law':
                item.to = '/hr-compliance/laws';
                break;
            case 'lawalerts':
                item.to = '/hr-compliance/law-alerts';
                break;
            case 'hrfitnesstest':
                item.to = '/hr-compliance/hr-assessment/start';
                break;
            case 'myintelligencedashboard':
                item.to = '/mineral-intelligence';
                break;
            case 'myinsights':
                item.to = '/mineral-intelligence/insights';
                break;
            default:
                item.to = '/futurework';
                break;
        }
        return item;
    });

    return items;
};

export const getLearnSubNavItems = items => {
    items.map(item => {
        switch (item.state) {
            case 'coursecatalog':
                item.to = '/training/course-catalog';
                break;
            case 'mycourses':
                item.to = '/training/my-courses';
                break;
            case 'trackdashboard':
                item.to = '/training/track/dashboard';
                break;
            case 'learnuserguide':
                item.to =
                    'https://www.thinkhr.com/hrhotline/content_filler/pdfs/pdf_download.php?pdfID=R3pVQ2x6dXkxNjUwVGhVclcxVVBKdz09&brokerID=TVZ6b3VubTBzZUNTbzNyYm5QSDdsQT09&ag=QWUxUXRQYXdTb0NrZnZnMEpFUWJ6UT09&source=TUVkc3FsSm1SZVBOUUlKa3lvOTIrdz09&linkID=172&hash_code=a07b6d7c76b81bb0fc2b33834b1ab042cad21075ac7a';
                break;
            default:
                break;
        }
        return item;
    });

    return items;
};

export const getLearnUrl = (
    route,
    sectionId,
    categoryId,
    categoryName,
    subcategoryId,
    subcategory,
    userProgressId,
    extraSectionId
) => {
    let iframeUrl = '';
    const categoryParam = categoryId || 18;
    const categoryNameParam = categoryName || 'Workplace+Safety';
    const subcategoryName =
        !isEmpty(subcategoryId) && !isEmpty(subcategory)
            ? `&subcategoryid=${subcategoryId}&subcategory=${subcategory}`
            : '';

    switch (route) {
        case 'course-catalog':
            iframeUrl = '/blocks/course_categories/pages/catalog.php';
            break;
        case 'my-courses':
            iframeUrl = '/blocks/course_categories/pages/my_courses.php';
            break;
        case 'course':
            iframeUrl = `/course/view.php?id=${sectionId}`;
            break;
        case 'scorm':
            iframeUrl = `/mod/scorm/view.php?id=${sectionId}`;
            break;
        case 'safety-courses':
            iframeUrl = encodeURIComponent(
                `/blocks/course_categories/pages/courses_by_category.php?categoryid=${categoryParam}&categoryname=${categoryNameParam}${subcategoryName}`
            );
            break;
        case 'my-certificates':
            iframeUrl = `/blocks/course_categories/pages/my_certificates.php`;
            break;
        case 'track':
            switch (sectionId) {
                case 'dashboard':
                    iframeUrl = '/blocks/tracks/pages/dashboard.php';
                    break;
                case 'add':
                    iframeUrl = '/blocks/tracks/pages/track.php';
                    break;
                case 'edit':
                    iframeUrl = `/blocks/tracks/pages/track.php?id=${extraSectionId}`;
                    break;
                case 'employees':
                    iframeUrl = `/blocks/tracks/pages/employees.php?trackid=${extraSectionId}`;
                    break;
                default:
                    iframeUrl = '/blocks/tracks/pages/dashboard.php';
                    break;
            }
            break;
        case 'user-progress':
            iframeUrl = `/tracks/pages/user_progress.php?id=${userProgressId}`;
            break;
        case 'force-course-completion':
            iframeUrl = `/blocks/course_completion_override/pages/force-course-completion-user.php`;
            break;
        case 'packages':
            iframeUrl = `/local/package/pages/package.php`;
            break;
        case 'dashboard-report':
            switch (sectionId) {
                case '':
                    iframeUrl = `/local/dashboard/pages/`;
                    break;
                case 'registered-users':
                    iframeUrl = `/local/dashboard/pages/registered_users.php?partnerid=${extraSectionId}`;
                    break;
                case 'assigned-courses':
                    iframeUrl = `/local/dashboard/pages/assigned_courses.php?partnerid=${extraSectionId}`;
                    break;
                case 'completed-courses':
                    iframeUrl = `/local/dashboard/pages/courses_completed.php?partnerid=${extraSectionId}`;
                    break;
                case 'employee-workspace':
                    iframeUrl = `/local/dashboard/pages/employee_workspace.php?userid=${extraSectionId}`;
                    break;
                default:
                    break;
            }
            break;
        case 'tools':
            switch (sectionId) {
                case 'skillsoft-course-uploader':
                    iframeUrl = `/local/upload_course/pages/upload_courses.php`;
                    break;
                case 'custom-course-uploader':
                    iframeUrl = `/local/custom_course_uploader/pages/courses_uploaded.php`;
                    break;
                case 'course-replacement':
                    iframeUrl = `/local/upload_course/pages/replace_courses.php`;
                    break;
                case 'course-selector':
                    iframeUrl = `/local/course_selector/pages/`;
                    break;
                default:
                    break;
            }
            break;
        case 'user-activity-report':
            iframeUrl = `/local/reports/pages/user_activity_report.php`;
            break;
        default:
            break;
    }

    return iframeUrl + window.document.location.hash;
};

export const getFormsAndLettersSubNavMainItems = items => {
    items.map(item => {
        switch (item.state) {
            case 'forms':
                item.to = '/templates/forms';
                break;
            case 'letters':
                item.to = '/templates/letters';
                break;
            case 'policies':
                item.to = '/templates/policies';
                break;
            case 'toolkits':
                item.to = '/templates/toolkits';
                break;
            case 'oshareporting':
                item.to = '/safety/osha';
                break;
            default:
                item.to = '/futurework';
                break;
        }
        return item;
    });

    return items;
};

export const getHRToolsSubNavMainItems = items => {
    return items.map(item => {
        switch (item.state) {
            case 'anonymousreporting':
                item.to = '/hr-tools/anonymous-reporting';
                item.permission = APP_PERMISSIONS.anonymousReportingAll;
                break;
            case 'benefitsdocumentcreator':
                item.to = '/hr-tools/benefits-document-creator';
                break;
            case 'jobdescriptionbuilder':
                item.to = '/hr-tools/job-description-builder';
                break;
            case 'salarycomparetool':
                item.to = '/hr-tools/salary-compare';
                break;
            case 'calculators':
                item.to = '/hr-tools/calculators';
                break;
            case 'minimumwagemap':
                item.to = '/hr-tools/minimum-wage-map';
                break;
            case 'aca-reporting':
                item.to = '/hr-tools/aca-reporting';
                break;
            default:
                item.to = '/futurework';
                break;
        }
        return item;
    });
};

export const getFundamentalSubNavMainItems = items => {
    items.map(item => {
        switch (item.state) {
            case '2MHR':
                item.to = '/resources/videos';
                break;
            case 'webinars':
                item.to = '/resources/webinars/upcoming';
                break;
            case 'qa':
                item.to = '/resources/q-and-a';
                break;
            case 'hrchecklist':
                item.to = '/resources/hr-checklists';
                break;
            case 'guide/whitepapers':
                item.to = '/resources/guides';
                break;
            case 'charts':
                item.to = '/resources/charts';
                break;
            default:
                break;
        }
        return item;
    });

    return items;
};

export const getNavigationSearchOptions = items => {
    const searchItems = [];

    items.forEach(item => {
        searchItems.push({
            value: item.state,
            label: item.title,
        });

        const subNavItems = get(item, 'subnav.main', []);

        if (!isEmpty(subNavItems)) {
            subNavItems.forEach(subNavItem => {
                searchItems.push({
                    value: subNavItem.state,
                    label: subNavItem.label,
                    parent: item.state,
                });
            });
        }
    });

    return searchItems;
};

export const getReportingSubNavMainItems = items => {
    items.map(item => {
        switch (item.state) {
            case 'brokenlinksreport':
                item.to = '/reporting/broken-links-report';
                break;
            case 'administrationreports':
                item.to = '/reporting/administration-reports';
                break;
            case 'clientusage':
                item.to = '/reporting/client-usage';
                break;
            default:
                break;
        }
        return item;
    });

    return items;
};

export const getSafetySubNavMainItems = items => {
    return items.map(item => {
        switch (item.state) {
            case 'safetyadvisor':
                item.to = '/my-cases/create';
                break;
            case 'oshareporting':
                item.to = '/safety/osha';
                break;
            case 'safetycourses':
                item.to = '/training/safety-courses';
                break;
            case 'safetyresources':
                item.to = '/safety/resources';
                break;
            case 'safetycontentlibrary':
                item.to = '/safety/content-library';
                break;
            case 'safetymanualtemplate':
                item.to = SAFETY_MANUAL_TEMPLATE_ID;
                break;
            default:
                item.to = '/futurework';
                break;
        }
        return item;
    });
};

export const getSubNavToProp = (parentState, subNavItem) => {
    if (subNavItem.state === 'handbooks') {
        return '/company-policies/handbooks';
    } else if (subNavItem.state === 'employeehandbooks') {
        return '/company-policies/handbooks-express';
    } else if (subNavItem.state === 'policycustomization') {
        return '/company-policies/policy-customization';
    } else if (subNavItem.state === 'documents') {
        return '/company-policies/documents/list';
    } else if (subNavItem.state === 'jobdescriptionlibrary') {
        return '/tools/jobs';
    } else if (subNavItem.state === 'compliancecalendar') {
        return '/hr-compliance/compliance-calendar';
    } else if (
        parentState === 'resources' ||
        parentState === 'templates' ||
        parentState === 'reporting' ||
        parentState === 'training' ||
        parentState === 'hrtools' ||
        parentState === 'safety' ||
        parentState === 'safetyehs' ||
        parentState === 'help'
    ) {
        return {
            pathname: subNavItem.to,
            state: subNavItem,
            currentTime: new Date().getTime(),
        };
    } else if (parentState === 'compliance' || parentState === 'myintelligencedashboard') {
        return {
            pathname: subNavItem.to,
            state: subNavItem,
            hash: subNavItem.hash || '',
        };
    } else if (parentState === 'ticketing') {
        return subNavItem.to;
    } else {
        return '/futurework';
    }
};

export const getTicketingSubNavItems = items => {
    items = items.map(item => {
        switch (item.state) {
            case 'tickets':
                item.to = '/ticketing/tickets';
                break;
            case 'userlookup':
                item.to = '/ticketing/user-lookup';
                break;
            case 'preppedanswers':
                item.to = '/ticketing/prepped-answers';
                break;
            case 'transferticket':
                item.to = '/ticketing/ticket-transfer';
                break;
            case 'assignments':
                item.to = '/ticketing/assignments';
                break;
            case 'unknownTickets':
                item.to = '/ticketing/unknown-tickets';
                break;
            case 'vpasuggestion':
                item.to = '/ticketing/vpa_requests';
                break;
            default:
                item.to = '/futurework';
                break;
        }
        return item;
    });

    return items;
};

export const getTopicNavigationToProp = (state, navItem) => {
    let path = navItem.label.toLowerCase();
    path = _replace(path, new RegExp('&', 'g'), 'and');
    path = _replace(path, new RegExp(' ', 'g'), '-');

    if (state === 'templates' || state === 'resources') {
        return {
            pathname: `/${state}/${path}`,
            state: navItem,
        };
    } else if (state === 'compliance' || state === 'myintelligencedashboard') {
        return {
            pathname: `/hr-compliance/${path}`,
            state: navItem,
        };
    }
    return '/futurework';
};

export const hasSubnav = history => {
    const { pathname } = history.location;
    const contains = (src, test) => {
        return src.indexOf(test) !== -1;
    };

    return (
        contains(pathname, '/admin/') ||
        contains(pathname, '/handbooks/') ||
        contains(pathname, 'profile') ||
        contains(pathname, 'documents') ||
        contains(pathname, 'osha') ||
        contains(pathname, 'ticketing') ||
        contains(pathname, 'case-cave') ||
        contains(pathname, 'hr-tools/benefits-document-creator') ||
        contains(pathname, 'admin/bulk-change-tool') ||
        contains(pathname, '/whp/whp-upgrade') ||
        contains(pathname, '/hr-tools/aca-reporting/managed-questionnaire') ||
        (contains(pathname, '/reporting/') && renderCheck('md', 'greater'))
    );
};

export const setUrlFilters = (filters, history, groupId, page, hashKey, search, featuredFlag) => {
    const searchFilters = isEmpty(filters) ? '' : `filters=${JSON.stringify(filters)}`;
    const searchGroupId = !isEmpty(groupId) ? `&groupId=${groupId}` : '';
    const searchPage = !isEmpty(page) ? `&page=${page}` : '';
    hashKey = isEmpty(hashKey) ? '' : `#${hashKey}`;
    const querySearch = isEmpty(search) ? '' : `query=${encodeURIComponent(search)}&`;
    const showFlag = isEmpty(featuredFlag) ? '' : featuredFlag;

    history.push({
        search: `?${showFlag}${querySearch}${searchFilters}${searchGroupId}${searchPage}${hashKey}`,
    });
};

export const getUrlFilters = (history, hashKeyToRemove) => {
    let queryParams = get(history, 'location.search', '') || get(history, 'location.hash', '');

    if (hashKeyToRemove) {
        queryParams = queryParams.replace(`?${hashKeyToRemove}`, '');
        queryParams = queryParams.replace(hashKeyToRemove, '');
    }

    const queryParamsParse = queryString.parse(queryParams);
    const filters = queryParamsParse.filters ? JSON.parse(queryParamsParse.filters) : '';

    if (filters.fromDate) {
        filters.fromDate = new Date(filters.fromDate);
    }

    if (filters.toDate) {
        filters.toDate = new Date(filters.toDate);
    }

    if (filters.sort === ' title') {
        filters.sort = '+title';
    } else if (filters.sort === ' fields.title') {
        filters.sort = '+fields.title';
    }

    return filters;
};

export const getUrlGroup = history => {
    const queryParams = get(history, 'location.search', '');
    const queryParamsParse = queryString.parse(queryParams);
    let groupId;
    if (queryParamsParse.groupId) {
        const currentParams = queryParams.split('&groupId=');
        let currentGroupId = currentParams[1].split('&page');
        currentGroupId = decodeURIComponent(currentGroupId[0]);
        groupId = !isEmpty(currentGroupId) ? currentGroupId : '';
    }

    return groupId;
};

export const getUrlPage = history => {
    const queryParams = get(history, 'location.search', '');
    const queryParamsParse = queryString.parse(queryParams);
    return !isEmpty(queryParamsParse.page) ? Number(queryParamsParse.page) : 0;
};

export const backFiltersFunctionality = (
    previousProps,
    currentProps,
    resetOrUpdateFilters,
    fetchDocuments,
    defaultFilters,
    returnData = false,
    onChangeGroupPagination
) => {
    const { match: previousMatch } = previousProps;
    const { url: previousUrl } = previousMatch;

    const { match } = currentProps;
    const { url } = match;

    const previousAction = get(previousProps, 'history.action', '');
    const currentAction = get(currentProps, 'history.action', '');
    const previousParams = get(previousProps, 'location.search', '');
    const currentParams = get(currentProps, 'location.search', '');
    const previousFilters = previousParams.split('&groupId=');
    const currentFilters = currentParams.split('&groupId=');
    const previousFiltersDecode = decodeURIComponent(previousFilters[0].replace('?filters=', ''));
    const currentFiltersDecode = decodeURIComponent(currentFilters[0].replace('?filters=', ''));
    const previousParamsParse = queryString.parse(previousParams);
    const currentParamsParse = queryString.parse(currentParams);
    let currentGroupId;
    if (currentParamsParse.groupId) {
        currentGroupId = currentFilters[1].split('&page');
        currentGroupId = decodeURIComponent(currentGroupId[0]);
    }
    const specialCasePage =
        currentParamsParse.page > previousParamsParse.page &&
        previousAction === 'POP' &&
        currentAction === 'POP';

    if (previousUrl !== url) {
        if (!_isEqual(_sortBy(previousFiltersDecode), _sortBy(currentFiltersDecode))) {
            if (currentFilters[0] !== decodeURIComponent(currentFilters[0])) {
                const filterFromUrl = JSON.parse(currentFiltersDecode);
                const allFilters = getAllFilters(defaultFilters, filterFromUrl);
                if (!isEmpty(currentGroupId)) {
                    resetOrUpdateFilters(
                        allFilters,
                        currentGroupId,
                        Number(currentParamsParse.page) || 0
                    );
                } else if (returnData) {
                    return allFilters;
                } else {
                    fetchDocuments(allFilters);
                }
            } else if (isEmpty(currentFilters[0])) {
                if (returnData) {
                    return null;
                } else {
                    resetOrUpdateFilters();
                }
            }
        } else {
            resetOrUpdateFilters();
        }
    } else if (!_isEqual(_sortBy(previousFiltersDecode), _sortBy(currentFiltersDecode))) {
        if (currentFilters[0] !== decodeURIComponent(currentFilters[0])) {
            const filterFromUrl = JSON.parse(currentFiltersDecode);
            const allFilters = getAllFilters(defaultFilters, filterFromUrl);
            if (!isEmpty(currentGroupId)) {
                resetOrUpdateFilters(
                    allFilters,
                    currentGroupId,
                    Number(currentParamsParse.page) || 0
                );
            } else if (returnData) {
                return allFilters;
            } else {
                fetchDocuments(allFilters, Number(currentParamsParse.page) || 0);
            }
        } else if (isEmpty(currentFilters[0])) {
            if (returnData) {
                return null;
            } else {
                resetOrUpdateFilters();
            }
        }
    } else if (!_isEqual(previousParamsParse.groupId, currentParamsParse.groupId)) {
        if (returnData) {
            return {
                groupId: currentGroupId,
                options: {
                    page: Number(currentParamsParse.page) || 0,
                    back: true,
                },
                function: 'onChangeGroupPagination',
            };
        } else {
            onChangeGroupPagination(currentGroupId, {
                page: Number(currentParamsParse.page) || 1,
                back: true,
            });
        }
    } else if (currentParamsParse.page < previousParamsParse.page || specialCasePage) {
        if (returnData) {
            return {
                groupId: currentGroupId,
                options: {
                    page: Number(currentParamsParse.page),
                    back: true,
                },
                function: 'onChangeGroupPagination',
            };
        } else {
            onChangeGroupPagination(currentGroupId, {
                page: Number(currentParamsParse.page),
                back: true,
            });
        }
    }
    return false;
};

export const getMenuItems = (items = [], batchItems = [], itemsLabel, batchItemsLabel) => {
    const ContentTooltip = styled.span`
        cursor: not-allowed;
        padding: 6.5px 20px;
    `;

    return (
        <>
            {batchItems.length > 0 && (
                <Menu
                    margin={2.5}
                    ButtonProps={{ children: batchItemsLabel || 'Batch Actions' }}
                    placement="bottom-end"
                >
                    {batchItems.map(({ title, ...item }) => (
                        <MenuItem key={item.id}>
                            {item.tooltipContent ? (
                                <Typography>
                                    <Tooltip content={item.tooltipContent} placement="left">
                                        <ContentTooltip>{title}</ContentTooltip>
                                    </Tooltip>
                                </Typography>
                            ) : (
                                <MenuLink {...item}>{title}</MenuLink>
                            )}
                        </MenuItem>
                    ))}
                </Menu>
            )}
            {items.length > 0 && (
                <Menu
                    margin={2.5}
                    ButtonProps={{ children: itemsLabel || 'Create' }}
                    placement="bottom-end"
                >
                    {items.map(({ title, ...item }) => (
                        <MenuItem key={item.id}>
                            <MenuLink {...item}>{title}</MenuLink>
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </>
    );
};

export const navItemsNotBeClickable = () => {
    const items = [];

    return items;
};

export const formatAdminNavItems = (navItems, isClientRole) => {
    return navItems.map(item => {
        if (item.title === 'Companies') {
            return {
                ...item,
                title: isClientRole ? 'Company' : 'Companies',
                to: isClientRole ? '/admin/company' : '/admin/companies',
            };
        }
        return item;
    });
};

export const hasStateInMenus = (menuList, stateToFind) => {
    return menuList.some(menuList => {
        if (menuList.state === stateToFind) {
            return true;
        }

        if (menuList.subnav && Array.isArray(menuList.subnav.main)) {
            return menuList.subnav.main.some(subItem => subItem.state === stateToFind);
        }

        return false;
    });
};

export const getHelpCenterSubNavItems = items => {
    return items.map(item => {
        switch (item.state) {
            case 'helptopics':
                item.to = '/help-center';
                break;
            case 'contacttechnicalsupport':
                item.to = '/my-cases/create-technical-support';
                item.permissionToHide = APP_PERMISSIONS.platformliteHelpHide;

                break;
            case 'contactanhrexpert':
                item.to = '/my-cases/create';
                item.permissions = APP_PERMISSIONS.askTheExpert;
                item.permissionToHide = APP_PERMISSIONS.platformliteHelpHide;

                break;
            case 'chatwithtechnicalsupport':
                item.id = 'vergigchat';
                item.permissionToHide = APP_PERMISSIONS.platformliteHelpHide;

                break;
            default:
                item.to = '/futurework';
                break;
        }

        return item;
    });
};
