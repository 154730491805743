import { bool, func, object } from 'prop-types';
import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import Typography from 'ravenjs/lib/Typography';

import { get, isEmpty } from 'ravenjs/utils/lodash';
import { getThemeProps } from 'ravenjs/utils/theme';

import Acl from 'modules/acl';
import { actions as pendoActions } from 'modules/pendo';

import { HelpCloseIcon, HelpIcon } from 'components/Icons';
import withPublicContent from 'components/PublicContent/PublicContentHOC';
import { DEFAULT_COLORS } from 'constants/colors';
import { EVENTS } from 'constants/events';
import { IFRAME_SECTIONS, PENDO_CLASSNAME, VERGIC_JS } from 'constants/helpCenter';
import { APP_PERMISSIONS } from 'constants/permissions';
import HelpWidget from './HelpWidget';

const HelpMenu = styled.div`
    background-color: ${({ theme }) =>
        getThemeProps('palette.secondary.gradientColor', DEFAULT_COLORS.BLUE, {
            theme,
        })};
    bottom: 10px;
    cursor: pointer;
    left: auto;
    margin: 0 0 37px 0;
    position: fixed;
    right: 10px;
    top: auto;
    border-radius: 5px;
    z-index: 19001;
`;

const HelpStyled = styled.div`
    bottom: 10px;
    cursor: pointer;
    left: auto;
    margin: 0;
    position: fixed;
    right: 10px;
    top: auto;
    z-index: 19000;
    display: flex;
    align-items: center;
    width: 105px;
    height: 31px;
    background-color: ${DEFAULT_COLORS.BLACK};
    color: ${DEFAULT_COLORS.WHITE};
    border-radius: 150px;
    text-align: center;
    padding: 6px 6px 6px 14px;
    transition: width 300ms, padding 300ms;
    &.helpCenterStyledIframe {
        width: 31px;
        padding: 6px;
        .helpCenterStyledText {
            transition: opacity 400ms;
            opacity: 0;
            width: 0;
            white-space: nowrap;
            font-size: 0;
        }
        .helpCenterStyledTextIcon {
            margin-left: 0;
        }
        &:hover {
            transition: all 400ms cubic-bezier(0.62, 0.1, 0.5, 1);
            width: 105px;
            padding: 6px 6px 6px 14px;
            .helpCenterStyledText {
                transition: opacity 700ms, width 1ms linear 270ms font-size 1ms linear 270ms;
                opacity: 1;
                width: auto;
                font-size: 14px !important;
            }
            .helpCenterStyledTextIcon {
                margin-left: 9px;
            }
        }
    }
`;

const HelpStyledText = styled(Typography)`
    font-size: 14px !important;
    margin: 0 !important;
    font-weight: 500!IMPORTANT;
`;

const HelpStyledIcon = styled.div`
    position: relative;
    display: flex;
    margin-bottom: 2px;
    margin-left: 9px;
    cursor: pointer;
`;

class Help extends Component {
    static propTypes = {
        history: object.isRequired,
        sendPendoEvent: func.isRequired,
        isSoftLogin: bool.isRequired,
        openLoginPrompt: func.isRequired,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            isVergigLoaded: false,
            open: false,
            pathname: get(props, 'history.location.pathname', ''),
        };
        this.routeListener(props.history);
    }

    componentDidMount = () => {
        window.onscroll = () => {
            this.handleHelpCenterButtonScroll();
        };

        this.handleLoadVergig();
    };

    componentDidUpdate = () => {
        this.handleHelpCenterButtonScroll();
    };

    routeListener = history => {
        history.listen(location => {
            const { pathname } = this.state;
            if (location.pathname !== pathname) {
                this.setState({
                    pathname: location.pathname,
                });
            }
        });
    };

    loadVergicScript = () => {
        const script = document.createElement('script');
        script.src = `${VERGIC_JS}?t=${new Date().getTime()}`;
        document.body.appendChild(script);

        script.onload = () => {
            this.setState({
                isVergigLoaded: true,
            });
        };
    };

    isVergigScriptLoaded = () => {
        let isLoaded = false;
        const scripts = document.getElementsByTagName('script');

        if (!isEmpty(scripts) && Array.isArray(scripts)) {
            scripts.forEach(item => {
                if (item.src.indexOf(VERGIC_JS) !== -1) {
                    isLoaded = true;
                }
            });
        }

        return isLoaded;
    };

    onClick = (options = false) => {
        const { history, sendPendoEvent, isSoftLogin, openLoginPrompt } = this.props;
        const { open } = this.state;
        const path = get(history, 'location.pathname', '');
        const outside = get(options, 'outside', false);

        if (!isSoftLogin) {
            if (!outside) {
                sendPendoEvent({
                    event: EVENTS.HELP_ICON.EVENT,
                    eventProperties: {
                        description: `${EVENTS.HELP_ICON.EVENT_PROPERTIES.DESCRIPTION} ${path}`,
                    },
                });
            }

            this.setState(
                {
                    isSimulatedOpen: !options.isTrusted,
                    open: outside ? false : !open,
                },
                () => {
                    this.handleHelpCenterButtonScroll();
                }
            );
        } else {
            openLoginPrompt();
        }
    };

    isVisible = pathname => {
        return pathname.indexOf('auth') === -1 && pathname.indexOf('public') === -1;
    };

    handleLoadVergig = () => {
        if (!this.isVergigScriptLoaded()) {
            this.loadVergicScript();
        }
    };

    handleHelpCenterButtonScroll = () => {
        const { open } = this.state;
        const helpButtonId = open ? 'openedHelpCenterWidget' : 'openHelpCenterWidget';
        const helpCenterButton = document.getElementById(helpButtonId);
        const mineralFooter = document.getElementById('mineralFooter');

        if (helpCenterButton && mineralFooter) {
            const helpMenu = document.getElementById('helpMenu');
            const mineralFooterRect = mineralFooter.getBoundingClientRect();
            const helpCenterPositionBottom =
                helpCenterButton.offsetTop + helpCenterButton.offsetHeight;
            const isMineralFooterVisible =
                mineralFooterRect.top < window.innerHeight && mineralFooterRect.bottom >= 0;

            if (isMineralFooterVisible) {
                const diffheight = helpCenterPositionBottom - mineralFooterRect.y;
                const style = window.getComputedStyle(helpCenterButton);
                const addBottom = parseInt(style.getPropertyValue('bottom'), 10) + diffheight + 24;
                helpCenterButton.style.bottom = `${addBottom}px`;
                if (open) {
                    helpMenu.style.bottom = `${addBottom}px`;
                }
            } else {
                helpCenterButton.style.bottom = '';

                if (helpMenu) {
                    helpMenu.style.bottom = '';
                }
            }
        }
    };

    render() {
        const { history } = this.props;
        const { isVergigLoaded, open, pathname, isSimulatedOpen } = this.state;
        const p = pathname !== '' ? pathname : history.location.pathname;

        if (!this.isVisible(p)) {
            return null;
        }

        return open ? (
            <>
                <HelpMenu
                    id="helpMenu"
                    style={{
                        visibility: isSimulatedOpen ? 'hidden' : '',
                        opacity: isSimulatedOpen ? '0' : '',
                    }}
                >
                    <HelpWidget
                        history={history}
                        isVergigLoaded={isVergigLoaded}
                        onClick={this.onClick}
                    />
                </HelpMenu>
                <HelpStyled id="openedHelpCenterWidget" onClick={this.onClick}>
                    <HelpStyledText type="paragraph">Get Help</HelpStyledText>
                    <HelpStyledIcon>
                        <HelpCloseIcon fill={DEFAULT_COLORS.WHITE} width="18" height="18" />
                    </HelpStyledIcon>
                </HelpStyled>
            </>
        ) : (
            <HelpStyled
                id="openHelpCenterWidget"
                className={IFRAME_SECTIONS.indexOf(pathname) !== -1 ? 'helpCenterStyledIframe' : ''}
                onClick={this.onClick}
            >
                <HelpStyledText className="helpCenterStyledText" type="paragraph">
                    Get Help
                </HelpStyledText>
                <HelpStyledIcon className="helpCenterStyledTextIcon">
                    <HelpIcon
                        fill={DEFAULT_COLORS.WHITE}
                        fontFill={DEFAULT_COLORS.WHITE}
                        width="18"
                        height="18"
                        className={
                            Acl.check(APP_PERMISSIONS.userAssistanceClientAdmin)
                                ? PENDO_CLASSNAME
                                : ''
                        }
                    />
                </HelpStyledIcon>
            </HelpStyled>
        );
    }
}

const mapDispatchToProps = {
    sendPendoEvent: pendoActions.sendPendoEvent,
};

export { Help as HelpUnwrapped };
export default withPublicContent(connect(null, mapDispatchToProps)(withTheme(Help)));
