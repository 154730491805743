import { call, put, select } from 'redux-saga/effects';
import { get } from 'ravenjs/utils/lodash';

import { selectors as userSelectors } from 'modules/user';

import { getIpmParams } from 'utils/common';
import {
    inProductMarketingSendEmailAPI,
    fetchUpsellAdsAPI,
    fetchPricingAPI,
    fetchPaymentPageRedirectUrlAPI,
    fetchUpgradeAdsAPI,
    postUpgradeAdsAPI,
    deleteUpgradeAdAPI,
    getUpgradeAdByIdAPI,
    updateUpgradeAdAPI,
} from 'modules/apis';

import * as inProductMarketingActions from '../actions';

/**
 * Function for send email
 *
 * @method sendEmailSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object} location
 */
export function* sendEmailSaga(action) {
    try {
        const {
            payload: { params },
        } = action;
        const response = yield call(inProductMarketingSendEmailAPI, params);
        const results = get(response, 'data.results', {});
        yield put(inProductMarketingActions.sendEmail.success(results));
        return results;
    } catch (error) {
        yield put(inProductMarketingActions.sendEmail.error(error));
        return error;
    }
}

/**
 * Saga to fetch upsell ads
 *
 * @method fetchUpsellAdsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchUpsellAdsSaga(action) {
    try {
        let params = {};
        let companyNoSellItems = yield select(userSelectors.getCompanyNoSellItems);
        companyNoSellItems = companyNoSellItems
            .filter(item => item && typeof item === 'object' && 'licenseName' in item)
            .map(item => item.licenseName);
        params = {
            companyNoSellItems,
        };

        if (action.payload) {
            const {
                payload: { pageList, platformAdCampaign },
            } = action;
            const userCompanyLocations = yield select(userSelectors.getUserCompanyLocations);
            const currentUser = yield select(userSelectors.getUserInfo);
            const userCompany = yield select(userSelectors.getUserCompany);

            const encodedIpmParams = getIpmParams({
                pageList: pageList || [],
                userCompanyLocations,
                userCompany,
                currentUser,
            });
            params = {
                ...params,
                params: encodedIpmParams,
                platformAdCampaign,
            };
        }
        const response = yield call(fetchUpsellAdsAPI, params);
        const data = get(response, 'data.results', {});
        yield put(inProductMarketingActions.fetchUpsellAds.success(data));
        return data;
    } catch (error) {
        yield put(inProductMarketingActions.fetchUpsellAds.error(error));
        return error;
    }
}

export function* fetchPricingSaga(action) {
    try {
        const {
            payload: { payload },
        } = action;
        const response = yield call(fetchPricingAPI, payload);
        const data = get(response, 'data', {});
        yield put(inProductMarketingActions.fetchPricing.success(data));
        return data;
    } catch (error) {
        yield put(inProductMarketingActions.fetchPricing.error(error));
        return error;
    }
}

export function* fetchPaymentPageRedirectUrlSaga(action) {
    try {
        const {
            payload: { quoteId },
        } = action;
        const response = yield call(fetchPaymentPageRedirectUrlAPI, quoteId);
        const data = get(response, 'data', {});
        yield put(inProductMarketingActions.fetchPaymentPageRedirectUrl.success(data));
        return data;
    } catch (error) {
        yield put(inProductMarketingActions.fetchPaymentPageRedirectUrl.error(error));
        return error;
    }
}

/**
 * Saga to fetch upgrade ads
 *
 * @method fetchUpgradeAdsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* fetchUpgradeAdsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchUpgradeAdsAPI, payload);
        const data = get(response, 'data.bannerAds', {});
        yield put(inProductMarketingActions.fetchUpgradeAds.success(data));
        return data;
    } catch (error) {
        yield put(inProductMarketingActions.fetchUpgradeAds.error(error));
        return error;
    }
}

/**
 * Saga to post upgrade ads
 *
 * @method postUpgradeAdsSaga
 * @type   {Generator}
 * @param  {Object} action The redux action
 * @return {Object}
 */
export function* postUpgradeAdsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(postUpgradeAdsAPI, payload);
        const data = get(response, 'data.bannerAds', {});
        yield put(inProductMarketingActions.postUpgradeAds.success(data));
        return data;
    } catch (error) {
        yield put(inProductMarketingActions.postUpgradeAds.error(error));
        return error;
    }
}

/**
 * Saga to delete upgrade ad
 * @method deleteUpgradeAdSaga
 * @type   {Generator}
 * @param  {Object}     action The redux action
 * @return {Object}     banner
 */
export function* deleteUpgradeAdSaga(action) {
    try {
        const { payload: params } = action;
        const { adId } = params;
        const resp = yield call(deleteUpgradeAdAPI, adId);
        const data = get(resp, 'data', {});
        yield put(inProductMarketingActions.deleteUpgradeAd.success(data));
        return data;
    } catch (error) {
        yield put(inProductMarketingActions.deleteUpgradeAd.error(error));
        return error;
    }
}

/**
 * Saga to get upgrade ad by id
 * @method getUpgradeAdByIdSaga
 * @type   {Generator}
 * @param  {Object}     action The redux action
 * @return {Object}     banner
 */
export function* getUpgradeAdByIdSaga(action) {
    try {
        const { payload: params } = action;
        const { adId, partnerId } = params;
        const resp = yield call(getUpgradeAdByIdAPI, adId, partnerId);
        const data = get(resp, 'data.bannerAd', {});
        yield put(inProductMarketingActions.getUpgradeAdById.success(data));
        return data;
    } catch (error) {
        yield put(inProductMarketingActions.getUpgradeAdById.error(error));
        return error;
    }
}

/**
 * Saga to update upgrade ad by id
 * @method updateUpgradeAdSaga
 * @type   {Generator}
 * @param  {Object}     action The redux action
 * @return {Object}     banner
 */
export function* updateUpgradeAdSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(updateUpgradeAdAPI, payload);
        yield put(inProductMarketingActions.updateUpgradeAd.success(resp));
        return resp;
    } catch (error) {
        yield put(inProductMarketingActions.updateUpgradeAd.error(error));
        return error;
    }
}
