import { createActions } from 'utils/actions';

/**
 * Redux actions for fetching the list of Esignature Documents.
 *
 * @type {Object}
 */
export const fetchEsignatureUserDocuments = createActions('esigDocuments', 'FETCH_ESIG_LIST', {
    asPromise: true,
});

/**
 * Redux actions for downloading esign document.
 *
 * @type {Object}
 */
export const downloadDocumentForUser = createActions('esigDocuments', 'DOWNLOAD_ESIG_DOC', {
    asPromise: true,
});

/**
 * Redux actions for fetching esignature
 *
 * @type {Object}
 */
export const fetchSignature = createActions('esigDocuments', 'FETCH_SIGNATURE', {
    asPromise: true,
});

/**
 * Redux actions for fetching esigned documents
 *
 * @type {Object}
 */
export const fetchESignedDocuments = createActions('esigDocuments', 'FETCH_ESIGNED_LIST', {
    asPromise: true,
});

/**
 * Redux actions for fetching Embedded Hello Sign documents
 *
 * @type {Object}
 */
export const fetchEmbeddedHelloSignUrl = createActions(
    'esigDocuments',
    'FETCH_EMBEDDED_HELLO_SIGNURL',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for submitting Document for esignature
 *
 * @type {Object}
 */
export const postDocumentForSignatures = createActions('esigDocuments', 'DOCUMENT_SUBMIT_ESIGN', {
    asPromise: true,
});

/**
 * Redux actions for cancel the  esignature for a documente
 *
 * @type {Object}
 */
export const cancelAllEsignatureRequest = createActions('esigDocuments', 'CANCEL_ESIGNATURE', {
    asPromise: true,
});

/**
 * Redux actions for downloading  the  document from Hello Sign
 *
 * @type {Object}
 */
export const downloadDocumentFromHelloSign = createActions('esigDocuments', 'DOWNLOAD_DOCUMENT', {
    asPromise: true,
});

/**
 * Redux actions for fetching  the  sign document details
 *
 * @type {Object}
 */
export const fetchSignDocumentDetails = createActions('esigDocuments', 'DOCUMENT_DETAILS', {
    asPromise: true,
});

/**
 * Redux actions for saving the uploaded document
 *
 * @type {Object}
 */
export const saveUploadDocumentInfo = createActions('esigDocuments', 'SAVE_UPLOAD_DOCUMENT', {
    asPromise: true,
});

/**
 * Redux actions for submitting the uploaded document
 *
 * @type {Object}
 */
export const uploadDocumentForSignatures = createActions(
    'esigDocuments',
    'SUBMIT_UPLOAD_DOCUMENT',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for cancel the request for signers individually
 *
 * @type {Object}
 */
export const cancelIndividualEsignature = createActions(
    'esigDocuments',
    'CANCEL_INDIVIDUAL_ESIGNATURE',
    {
        asPromise: true,
    }
);

/**
 * Redux actions for sending the reminder request for esignatures
 *
 * @type {Object}
 */
export const remindEsignatureRequest = createActions('esigDocuments', 'SEND_REMIND_ESIGNATURE', {
    asPromise: true,
});

/**
 * Redux actions for fetching the list of document states.
 *
 * @type {Object}
 */
export const fetchDocumentStates = createActions('esigDocuments', 'FETCH_DOCUMENT_STATES', {
    asPromise: true,
});

/**
 * Redux actions for update translation for document.
 *
 * @type {Object}
 */
export const updateTranslation = createActions('esigDocuments', 'UPDATE_TRANSLATION', {
    asPromise: true,
});

/**
 * Redux actions for fetching  the  sign document details
 *
 * @type {Object}
 */
export const fetchDocumentEsignatureDetail = createActions(
    'esigDocuments',
    'FETCH_DOCUMENT_ESIGNATURE_DETAIL',
    {
        asPromise: true,
    }
);
