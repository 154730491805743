import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Switch, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { bool, func, object } from 'prop-types';
import queryString from 'query-string';

import { get, isEmpty } from 'ravenjs/utils/lodash';

import Acl from 'modules/acl';
import { actions as pendoActions } from 'modules/pendo';
import { APP_PERMISSIONS } from 'constants/permissions';
import { ROOT_PRIVATE_ROUTE, ROOT_PRIVATE_ROUTE_PLATFORM_LITE } from 'constants/routes';
import { INFO } from 'constants/version';
import { CLICK_TO_BUY } from 'constants/clickToBuy';
import { MODALS } from 'constants/modals';
import { actions as inProductMarketingActions } from 'modules/inProductMarketing';
import { selectors as authSelectors } from 'modules/auth';
import { selectors as userSelectors } from 'modules/user';
import { actions as uiActions } from 'modules/ui';
import {
    buildPayloadForInProductMarketing,
    hasFeatureFlag,
    cleanSafetyLocalStorage,
    setPreviousSafetyLocalStorage,
} from 'utils/common';
import { getOnClickBackCallback, hasSearch, pushesToSearchPage } from 'utils/search';
import ContentSearch from 'components/ContentSearch';
import HelpCenterSearchTypeAhead from 'components/HelpCenterSearchTypeAhead';

import { actions as brandingActions, selectors as brandingSelectors } from 'modules/branding';

import { pauseHotjarScript, resumeHotjarScript } from 'utils/hotjar';

import Route from './Route';

// Lazily load in all of the routes.
const Admin = lazy(() => import('../containers/Admin'));
const AIAssistant = lazy(() => import('../containers/AIAssistant'));
const Auth = lazy(() => import('../containers/Auth'));
const Dashboard = lazy(() => import('../containers/Dashboard'));
const DHEAudits = lazy(() => import('../containers/DHEAudits'));
const GuidedHRAssessment = lazy(() => import('../containers/GuidedHRAssessment'));
const HRTools = lazy(() => import('../containers/HRTools'));
const Impersonation = lazy(() => import('../containers/Impersonation'));
const LegalAndPrivacy = lazy(() => import('../containers/LegalAndPrivacy'));
const Profile = lazy(() => import('../containers/Profile'));
const Compliance = lazy(() => import('../containers/Compliance'));
const MineralIntelligence = lazy(() => import('../containers/MineralIntelligence'));
const MyTickets = lazy(() => import('../containers/MyTickets'));
const Tools = lazy(() => import('../containers/Tools'));
const Reporting = lazy(() => import('../containers/Reporting'));
const Search = lazy(() => import('../containers/Search'));
const FutureWork = lazy(() => import('../containers/FutureWork'));
const Templates = lazy(() => import('../containers/Templates'));
const Notifications = lazy(() => import('../containers/Notifications'));
const CompanyPolicies = lazy(() => import('../containers/CompanyPolicies'));
const Document = lazy(() => import('../containers/Document'));
const Public = lazy(() => import('../containers/Public'));
const MindmatrixSSO = lazy(() => import('../containers/MindmatrixSSO'));
const Prospect = lazy(() => import('../containers/Prospect'));
const Resources = lazy(() => import('../containers/Resources'));
const Safety = lazy(() => import('../containers/Safety'));
const TodoList = lazy(() => import('../containers/TodoList'));
const Training = lazy(() => import('../containers/Training'));
const HelpCenter = lazy(() => import('../containers/HelpCenter'));
const CaseCave = lazy(() => import('../containers/CaseCave'));
const OpenIdConnect = lazy(() => import('../containers/OpenIdConnect'));
const OpenIdRedirect = lazy(() => import('../containers/OpenIdRedirect'));
const MAPPortal = lazy(() => import('../containers/MAPPortal'));
const ClickToBuyWHP = lazy(() => import('../containers/ClickToBuy/WHP'));
const PaymentConfirmation = lazy(() =>
    import('../containers/ClickToBuy/components/PaymentConfirmation')
);
const Marketplace = lazy(() => import('../containers/ClickToBuy/Marketplace'));

class Routes extends Component {
    constructor(props) {
        super(props);
        const { brandingConfig } = this.props;
        this.updateBranding = this.updateBranding.bind(this);
        if (
            !brandingConfig.sprintTimestamp ||
            brandingConfig.sprintTimestamp < INFO.sprintTimestamp
        ) {
            this.updateBranding();
        }

        this.routeListener(props.history);
    }

    componentDidMount = () => {};

    setTicketingIframeRef = input => {
        this.ticketingiframeRef = input;
    };

    sendEmail = async (pendoId, optyName, source, adType) => {
        const { isImpersonatingUser, loggedUser, sendEmail, sendPendoEvent } = this.props;
        try {
            const params = buildPayloadForInProductMarketing(
                loggedUser,
                isImpersonatingUser,
                optyName,
                pendoId.replace('&', ''),
                source,
                adType
            );
            await sendEmail({ params });
            const previousSafetyContent = localStorage.getItem('previousSafetyContent')
                ? JSON.parse(localStorage.getItem('previousSafetyContent'))
                : {};
            const pendoEvent = {
                event: 'Sent Email - In-Product Marketing - Lead Success',
                eventProperties: {
                    GuideName: localStorage.getItem('guideName') || previousSafetyContent.guideName,
                    SafetyCategory:
                        localStorage.getItem('safetyCategory') ||
                        previousSafetyContent.safetyCategory,
                    SafetyTopic:
                        localStorage.getItem('safetyTopic') || previousSafetyContent.safetyTopic,
                    SafetyContent:
                        localStorage.getItem('safetyContent') ||
                        previousSafetyContent.safetyContent,
                    Source: localStorage.getItem('source') || previousSafetyContent.source,
                },
            };
            sendPendoEvent(pendoEvent);
            setPreviousSafetyLocalStorage();
            cleanSafetyLocalStorage();
            pendo.showGuideByName('In-Product Marketing - Lead Success');
        } catch (e) {
            pendo.showGuideByName('In-Product Marketing - Lead Failure');
        }
    };

    updateBranding = async (pendoId, optyName, source, adType) => {
        const {
            fetchBranding,
            loggedUser: { company },
        } = this.props;

        await fetchBranding(company.partnerId);
    };

    routeListener = history => {
        history.listen(location => {
            const locationHash = get(history, 'location.hash', '');
            const { brandingConfig, openModal } = this.props;

            if (
                !brandingConfig.sprintTimestamp ||
                brandingConfig.sprintTimestamp < INFO.sprintTimestamp
            ) {
                this.updateBranding();
            }

            if (location.pathname.includes('/ticketing')) {
                this.ticketingiframeRef &&
                    this.ticketingiframeRef.sendMessage({ route: location.pathname }, ['*']);
            } else if (!isEmpty(locationHash) && locationHash.includes('OptyName')) {
                const guideId = locationHash.split('OptyName=');
                const queryParamsParse = queryString.parse(locationHash);
                const source = queryParamsParse ? queryParamsParse.Source : '';
                const adType = queryParamsParse ? queryParamsParse.AdType : '';
                const optyName = queryParamsParse ? queryParamsParse.OptyName : '';

                history.push({
                    location: {
                        hash: '',
                    },
                });

                this.sendEmail(get(guideId, '0', ''), optyName, source, adType);
            } else if (!isEmpty(locationHash) && locationHash.includes('ariesAreasOfExpertise')) {
                openModal(MODALS.ARIES_INFORMATION, {
                    hideActions: true,
                    hideIcon: true,
                    paddingContainer: '0.25rem',
                });
            }
        });
    };

    render() {
        const { history } = this.props;
        const isFullScreenMode = hasFeatureFlag(history.location, 'showFullScreenMode');

        const p = get(history, 'location.pathname', null);

        if (!p.startsWith('/help-center')) {
            pauseHotjarScript();
        } else {
            resumeHotjarScript();
        }

        const defaultLandingPageRoute = Acl.check(APP_PERMISSIONS.platformliteCompanyDashboardView)
            ? ROOT_PRIVATE_ROUTE_PLATFORM_LITE
            : ROOT_PRIVATE_ROUTE;
        return (
            <Suspense fallback={<div />}>
                {hasSearch(p) && !isFullScreenMode && (
                    <ContentSearch
                        enablePushToSearchPage={pushesToSearchPage(p)}
                        onClickBack={getOnClickBackCallback(history)}
                    />
                )}
                {history.location.pathname.includes('/help-center') && (
                    <HelpCenterSearchTypeAhead history={history} />
                )}
                <Switch>
                    <Route path="/auth" render={props => <Auth {...props} />} isPublic />
                    <Route path="/aries" render={props => <AIAssistant {...props} />} />
                    <Route
                        path="/openid/redirect"
                        exact
                        render={props => <OpenIdRedirect {...props} />}
                        isPublic
                    />
                    <Route path="/openid" render={props => <OpenIdConnect {...props} />} isPublic />
                    <Route path="/dashboard" render={props => <Dashboard {...props} />} />
                    <Route path="/dhe-audits" render={props => <DHEAudits {...props} />} />
                    <Route path="/help-center" render={props => <HelpCenter {...props} />} />
                    <Route path="/hr-tools" render={props => <HRTools {...props} />} />
                    <Route
                        path="/partner-enablement"
                        permissions={APP_PERMISSIONS.resources}
                        render={props => <MindmatrixSSO {...props} />}
                    />
                    <Route path="/profile" component={Profile} />
                    <Route
                        path="/admin"
                        permissions={APP_PERMISSIONS.system}
                        render={props => <Admin {...props} />}
                    />
                    <Route path="/legal-and-privacy" component={LegalAndPrivacy} />
                    <Route
                        path="/training/:section/category/:categoryId/:categoryName/:subcategoryId/:subcategory"
                        render={props => <Training {...props} />}
                    />
                    <Route
                        path="/training/:section/category/:categoryId/:categoryName"
                        render={props => <Training {...props} />}
                    />
                    <Route
                        path="/training/:section/:sectionId/:extraSectionId"
                        render={props => <Training {...props} />}
                    />
                    <Route
                        path="/training/:section/:sectionId"
                        render={props => <Training {...props} />}
                    />
                    <Route path="/training/:section" render={props => <Training {...props} />} />
                    <Route
                        path="/impersonation/:action"
                        render={props => <Impersonation {...props} />}
                    />
                    <Route
                        path="/guided-hr-assessment"
                        render={props => <GuidedHRAssessment {...props} />}
                    />
                    <Route
                        path="/hr-compliance"
                        permissions={APP_PERMISSIONS.compliance}
                        render={props => <Compliance {...props} />}
                    />
                    <Route
                        path="/featured-content/:id/details"
                        permissions={APP_PERMISSIONS.compliance}
                        render={props => <Document {...props} />}
                    />
                    <Route
                        path="/mineral-intelligence"
                        render={props => <MineralIntelligence {...props} />}
                    />
                    <Route
                        path="/ticketing"
                        permissions={APP_PERMISSIONS.systemTicketingSystemView}
                        render={props => {
                            const { history } = props;

                            history.replace('/case-cave');

                            return (
                                <CaseCave
                                    {...props}
                                    setTicketingIframeRef={this.setTicketingIframeRef}
                                />
                            );
                        }}
                    />
                    <Route
                        path="/case-cave"
                        permissions={APP_PERMISSIONS.systemTicketingSystemView}
                        render={props => {
                            const { history, location } = props;
                            if (
                                location &&
                                location.pathname.includes('/case-cave/prepped_answers')
                            ) {
                                const newPath = location.pathname.replace(
                                    '/case-cave',
                                    '/ticketing'
                                );
                                history.replace(newPath);
                                return null;
                            }

                            return (
                                <CaseCave
                                    {...props}
                                    setTicketingIframeRef={this.setTicketingIframeRef}
                                />
                            );
                        }}
                    />
                    <Route path="/my-cases" render={props => <MyTickets {...props} />} />
                    <Route
                        path="/my-tickets"
                        render={props => {
                            // Redirecting legacy my-tickets url to my-cases,
                            // please refer GN-7395 for more details.
                            const { history, location } = props;
                            if (location && location.pathname.includes('/my-tickets')) {
                                const newPath = location.pathname.replace(
                                    '/my-tickets',
                                    '/my-cases'
                                );
                                history.replace(newPath);
                            }
                            return null;
                        }}
                    />
                    <Route path="/tools" render={props => <Tools {...props} />} />
                    <Route path="/reporting" render={props => <Reporting {...props} />} />
                    <Route
                        path="/search"
                        permissions={APP_PERMISSIONS.compliance}
                        render={props => <Search {...props} />}
                    />
                    <Route
                        path="/map-portal"
                        permissions={APP_PERMISSIONS.mineralAdvantageProgramDashboardView}
                        render={props => <MAPPortal {...props} />}
                    />
                    <Route path="/futurework" render={props => <FutureWork {...props} />} />
                    <Route path="/templates" render={props => <Templates {...props} />} />
                    <Route path="/resources" render={props => <Resources {...props} />} />
                    <Route path="/notifications" render={props => <Notifications {...props} />} />
                    <Route
                        path="/company-policies"
                        render={props => <CompanyPolicies {...props} />}
                    />

                    <Route
                        path="/click-to-buy/:productCode?/:invoiceID?"
                        render={props => {
                            const queryParams = get(props, 'location.search', '');
                            const queryParamsParsed = queryString.parse(queryParams);
                            const { productCode } = queryParamsParsed;
                            const productCodeTrimmed = productCode.trim();
                            const smartHandbookPlusProductKey = CLICK_TO_BUY.SEHP.SMART_HANDBOOK.QUOTE_KEY.trim();
                            const whpProductKey = CLICK_TO_BUY.WHP.WHP_UPGRADE.QUOTE_KEY.trim();

                            if (smartHandbookPlusProductKey.includes(productCodeTrimmed)) {
                                return (
                                    <PaymentConfirmation
                                        {...props}
                                        type={CLICK_TO_BUY.COMMON.TYPES.SEH_PLUS}
                                    />
                                );
                            } else if (whpProductKey.includes(productCodeTrimmed)) {
                                return (
                                    <PaymentConfirmation
                                        {...props}
                                        type={CLICK_TO_BUY.COMMON.TYPES.WHP}
                                    />
                                );
                            }
                            return null;
                        }}
                    />
                    <Route path="/prospect" render={props => <Prospect {...props} />} />
                    <Route path="/safety" render={props => <Safety {...props} />} />
                    <Route path="/todo" render={props => <TodoList {...props} />} />
                    <Route path="/whp/whp-upgrade" render={props => <ClickToBuyWHP {...props} />} />
                    <Route
                        path="/marketplace"
                        permissions={APP_PERMISSIONS.systemCompanyAdmin}
                        render={props => <Marketplace {...props} />}
                    />
                    <Route isPublic path="/public" render={props => <Public {...props} />} />
                    <Redirect from="/login" to="/auth/login" />
                    <Redirect from="/logout" to="/auth/logout" />
                    {/* Redirect all unknown routes to the `dashboard` for now */}
                    <Redirect to={defaultLandingPageRoute} />
                </Switch>
            </Suspense>
        );
    }
}

Routes.propTypes = {
    brandingConfig: object,
    history: object,
    isImpersonatingUser: bool.isRequired,
    loggedUser: object,
    sendEmail: func.isRequired,
    fetchBranding: func.isRequired,
    openModal: func.isRequired,
    sendPendoEvent: func.isRequired,
};

Routes.defaultProps = {
    brandingConfig: {},
    history: {},
    loggedUser: {},
};

const mapDispatchToProps = {
    sendEmail: inProductMarketingActions.sendEmail,
    fetchBranding: brandingActions.fetchBranding,
    sendPendoEvent: pendoActions.sendPendoEvent,
    openModal: uiActions.openModal,
};

const mapStateToProps = createStructuredSelector({
    isImpersonatingUser: authSelectors.isImpersonatingUser,
    loggedUser: userSelectors.getUser,
    brandingConfig: brandingSelectors.getBranding,
});

export { Routes as RoutesUnwrapped };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
