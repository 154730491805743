import _get from 'lodash/get';
import { call, put, select } from 'redux-saga/effects';

import { get } from 'ravenjs/utils/lodash';

import {
    acceptEulaAPI,
    bulkUserSubscriptionsAPI,
    checkUserSubscriptionStatusAPI,
    createUsersAdditionalDataAPI,
    fetchRolesAPI,
    fetchUserByIdAPI,
    fetchUserPreferencesByIdAPI,
    fetchUsersAdditionalDataAPI,
    getUsersAdditionalDataAPI,
    getUserSettingsAPI,
    resetPartnerSettingsAPI,
    sendMail,
    unsubscribeUserFromSubscriptionsAPI,
    updatePartnerConfigurationsAPI,
    updatePartnerSelfSettingsAPI,
    updateUserByIdAPI,
    updateUserPreferencesByIdAPI,
    updateUsersAdditionalDataAPI,
    updateUserSettingsAPI,
    updateUserTermsAndConditionsStatus,
} from 'modules/apis';
import * as userActions from '../actions';
import { getCompanyPrimaryLocation } from '../reducer';

/**
 * Saga to accept EULA
 *
 * @method acceptEULASaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* acceptEULASaga(action) {
    try {
        const { payload } = action;
        const response = yield call(acceptEulaAPI, payload);
        const message = _get(response, 'data');
        yield put(userActions.acceptEULA.success(message));
        return message;
    } catch (error) {
        yield put(userActions.acceptEULA.error(error));
        return error;
    }
}

/**
 * Formats the payload for the 'Send Mail' API
 *
 * @param   {string}    interestTrack
 * @param   {Object}    location
 * @return  {Object}
 */
export const formatPayloadForFeatureInterestMail = (interestTrack, location) => {
    let track;

    switch (interestTrack.replace(/(\s)/g, '_').toLowerCase()) {
        case 'handbooks_elite':
            track = 'Living Handbook';
            break;
        case 'multilingual_handbooks':
            track = 'Employee Handbook Builder Translate';
            break;
        case 'multi-state_handbook':
            track = 'Employee Handbook Builder MultiState';
            break;
        case 'handbook_elite_plus':
            track = 'Living Handbook Pro';
            break;
        default:
            track = '';
    }

    // TODO: Need to update the payload when the 'Send Email' JAPI is ready
    return {
        track,
    };
};

/**
 * Fetch info for a given user via a `userId`.
 *
 * @method fetchUserSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* fetchUserSaga(action) {
    try {
        // Extract the userId from the action.
        const { payload: userId } = action;
        // Make the call to fetch the user with the given `userId`.
        const resp = yield call(fetchUserByIdAPI, userId);
        // Extract the user from the valid response.
        const user = _get(resp, 'data.user', {});
        // Dispatch the `fetchUser` SUCCESS action.
        yield put(userActions.fetchUser.success(user));
        // Return the user.
        return user;
    } catch (error) {
        // Otherwise catch the error and dispatch the `fetchUser` ERROR action.
        yield put(userActions.fetchUser.error(error));
        // Return the error.
        return error;
    }
}

/**
 * Fetch the partner branding by the `userId`.
 *
 * @method fetchUserSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* fetchUserPreferencesSaga(action) {
    try {
        // Extract the userId from the action.
        const { payload: userId } = action;
        // Make the call to get user preferences with the supplied `userId`.
        const resp = yield call(fetchUserPreferencesByIdAPI, userId);
        // Extract the user preferences from the response.
        const preference = _get(resp, 'data.preference.preference', {});
        const preferenceId = get(resp, 'data.preference.id', '');
        // Dispatch the `fetchUserPreferences` SUCCESS action.
        let response;
        if (preferenceId) response = { preferenceId, ...preference };
        else response = preference;
        yield put(userActions.fetchUserPreferences.success(response));
        // Return the user preferences.
        return response;
    } catch (error) {
        yield put(userActions.fetchUserPreferences.error(error));
        // Return the error.
        return error;
    }
}

/**
 * Update info for a given user via userInfo.
 *
 * @method updateUserSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* updateUserPreferencesSaga(action) {
    try {
        // Extract the userInfo from the action.
        const { payload: userInfo } = action;
        // Make the call to update user preferences with the supplied `userInfo`.
        const resp = yield call(updateUserPreferencesByIdAPI, userInfo);
        // Extract the user preferences from the response.
        const preference = _get(resp, 'data.preference', {});
        // Dispatch the `updateUserPreferences` SUCCESS action.
        yield put(userActions.updateUserPreferences.success(preference));
        // Return the user preferences.
        return preference;
    } catch (error) {
        yield put(userActions.updateUserPreferences.error(error));
        // Return the error.
        return error;
    }
}

/**
 * Trigger user interest in a handbook feature
 *
 * @method triggerUserInterestInHandbookFeature
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* triggerUserInterestInHandbookFeatureSaga(action) {
    try {
        const {
            payload: { interestTrack },
        } = action;
        const primaryLocation = yield select(getCompanyPrimaryLocation);
        const payload = formatPayloadForFeatureInterestMail(interestTrack, primaryLocation);
        // TODO: Trigger send email API
        const response = yield call(sendMail, payload);

        yield put(userActions.triggerUserInterestInHandbookFeature.success(response));

        return response;
    } catch (error) {
        yield put(userActions.triggerUserInterestInHandbookFeature.error(error));
        // Return the error.
        return error;
    }
}

/**
 * Update info for a given user via a `userId`.
 *
 * @method updateUserSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* updateUserSaga(action) {
    try {
        // Extract the userId from the action.
        const { payload: userInfo } = action;
        // Make the call to update the user with the given `userId`.
        const resp = yield call(updateUserByIdAPI, userInfo);
        // Extract the updated user from the valid response.
        const user = _get(resp, 'data.user', {});
        // Dispatch the `updateUser` SUCCESS action.
        yield put(userActions.updateUser.success(user));
        // Return the user.
        return user;
    } catch (error) {
        yield put(userActions.updateUser.error(error));
        // Return the error.
        return error;
    }
}

/**
 * Update 'Terms and Conditions' status for the user
 *
 * @method updateUserTermsAndConditionsStatusSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* updateUserTermsAndConditionsStatusSaga(action) {
    try {
        const {
            payload: { userId },
        } = action;
        // Call the API to update the status
        const resp = yield call(updateUserTermsAndConditionsStatus, { userId });
        // Dispatch the 'SUCCESS' action
        yield put(userActions.updateUserTermsAndConditionsStatus.success(resp));
        return resp;
    } catch (e) {
        yield put(userActions.updateUserTermsAndConditionsStatus.error(e));
        return e;
    }
}

/**
 * Saga to check user subscription status
 *
 * @method checkUserSubscriptionStatusSaga
 * @param  {Object}               action The original redux-action
 */
export function* checkUserSubscriptionStatusSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(checkUserSubscriptionStatusAPI, payload);
        const status = _get(response, 'data');
        yield put(userActions.checkUserSubscriptionStatus.success(status));
    } catch (error) {
        yield put(userActions.checkUserSubscriptionStatus.error(error));
    }
}

/**
 * Saga to unsubscribe user from subscriptions
 *
 * @method unsubscribeUserFromSubscriptionsSaga
 * @param  {Object}               action The original redux-action
 */
export function* unsubscribeUserFromSubscriptionsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(unsubscribeUserFromSubscriptionsAPI, payload);
        const data = _get(response, 'data');
        yield put(userActions.unsubscribeUserFromSubscriptions.success(data));
    } catch (error) {
        yield put(userActions.unsubscribeUserFromSubscriptions.error(error));
    }
}

/**
 * Saga to bulk user subscriptions
 *
 * @method bulkUserSubscriptionsSaga
 * @param  {Object}               action The original redux-action
 */
export function* bulkUserSubscriptionsSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(bulkUserSubscriptionsAPI, payload);
        const data = _get(response, 'data');
        yield put(userActions.bulkUserSubscriptions.success(data));
    } catch (error) {
        yield put(userActions.bulkUserSubscriptions.error(error));
    }
}

/**
 * Fetch user role
 *
 * @method fetchUserRoleSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object} role
 */
export function* fetchUserRoleSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(fetchRolesAPI, payload);
        const role = _get(response, 'data.roles[0]', {});
        delete role.permissions;
        yield put(userActions.fetchUserRole.success(role));
        return role;
    } catch (e) {
        yield put(userActions.fetchUserRole.error(e));
        return e;
    }
}

/**
 * Update EULA status
 *
 * @method updateEULAStatus
 * @type   {Generator}
 * @param  {Object}          action The redux action
 */
export function* updateEULAStatusSaga(action) {
    try {
        const { payload } = action;
        yield put(userActions.updateEULAStatus.success(payload));
    } catch (e) {
        yield put(userActions.updateEULAStatus.error(e));
    }
}

/**
 * Update company details reminder status
 *
 * @method updateCompanyDetailsReminderStatus
 * @type   {Generator}
 * @param  {Object}          action The redux action
 */
export function* updateCompanyDetailsReminderStatusSaga(action) {
    try {
        const { payload } = action;
        yield put(userActions.updateCompanyDetailsReminderStatus.success(payload));
    } catch (e) {
        yield put(userActions.updateCompanyDetailsReminderStatus.error(e));
    }
}

/**
 * Saga to update partner settings
 *
 * @param   {Object}    action
 */
export function* updatePartnerSettingsSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(updatePartnerSelfSettingsAPI, payload);
        const partnerSettings = get(resp, 'data.partnersettings', {});
        yield put(userActions.updatePartnerSettings.success(partnerSettings));
    } catch (error) {
        yield put(userActions.updatePartnerSettings.error(error));
    }
}

/**
 * Saga to reset partner settings
 *
 * @method resetPartnerSettingsSaga
 * @param  {Object}               action The original redux-action
 * @return {Generator}
 */
export function* resetPartnerSettingsSaga(action) {
    try {
        const { payload: partnerId } = action;
        const response = yield call(resetPartnerSettingsAPI, partnerId);
        const partnerSettings = get(response, 'data', {});
        yield put(userActions.resetPartnerSettings.success(partnerSettings));
        return partnerSettings;
    } catch (error) {
        yield put(userActions.resetPartnerSettings.error(error));
        return error;
    }
}

/**
 * Update welcome insights status
 *
 * @method updateWelcomeInsightsStatusSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 */
export function* updateWelcomeInsightsStatusSaga(action) {
    try {
        const { payload } = action;
        yield put(userActions.updateWelcomeInsightsStatus.success(payload));
    } catch (e) {
        yield put(userActions.updateWelcomeInsightsStatus.error(e));
    }
}

/**
 * Update click on safety menu
 *
 * @method updateClickSafetyMenuSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 */
export function* updateClickSafetyMenuSaga(action) {
    try {
        const { payload } = action;
        yield put(userActions.updateClickSafetyMenu.success(payload));
    } catch (e) {
        yield put(userActions.updateClickSafetyMenu.error(e));
    }
}

/**
 * Saga to update partner configurations
 *
 * @param   {Object}    action
 */
export function* updatePartnerConfigurationsSaga(action) {
    try {
        const { payload } = action;
        yield call(updatePartnerConfigurationsAPI, payload);
        const partnerConfigurations = {
            bannerAdsTOSAcceptanceDate: true,
        };
        yield put(userActions.updatePartnerConfigurations.success(partnerConfigurations));
    } catch (error) {
        yield put(userActions.updatePartnerConfigurations.error(error));
    }
}

/**
 * Saga to update Import Status
 *
 * @param   {Object}    action
 */
export function* updateImportStatusSaga(action) {
    try {
        const { payload } = action;
        yield put(userActions.updateImportStatus.success(payload));
    } catch (error) {
        yield put(userActions.updateImportStatus.error(error));
    }
}

/**
 * fetch user additional data via userId
 *
 * @method fetchUsersAdditionalDataSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* fetchUsersAdditionalDataSaga(action) {
    try {
        const { payload: userInfo } = action;
        const resp = yield call(fetchUsersAdditionalDataAPI, userInfo);
        const data = _get(resp, 'data.usersAdditionalData', {});
        yield put(userActions.fetchUsersAdditionalData.success(data));
        return data;
    } catch (error) {
        yield put(userActions.fetchUsersAdditionalData.error(error));
        return error;
    }
}

/**
 * fetch user additional data via userId
 *
 * @method updateUsersAdditionalDataSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* updateUsersAdditionalDataSaga(action) {
    try {
        const { payload: userInfo } = action;
        const resp = yield call(updateUsersAdditionalDataAPI, userInfo);
        const data = _get(resp, 'data', {});
        yield put(userActions.updateUsersAdditionalData.success(data));
        return data;
    } catch (error) {
        yield put(userActions.updateUsersAdditionalData.error(error));
        return error;
    }
}

/**
 * create user additional data via userId
 *
 * @method CreateUsersAdditionalDataSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* createUsersAdditionalDataSaga(action) {
    try {
        const { payload: userInfo } = action;
        const resp = yield call(createUsersAdditionalDataAPI, userInfo);
        const data = _get(resp, 'data', {});
        yield put(userActions.createUsersAdditionalData.success(data));
        return data;
    } catch (error) {
        yield put(userActions.createUsersAdditionalData.error(error));
        return error;
    }
}

/**
 * fetch user additional data via userId
 *
 * @method getUsersAdditionalDataSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object|Error}
 */
export function* getUsersAdditionalDataSaga(action) {
    try {
        const { payload: userInfo } = action;
        const resp = yield call(getUsersAdditionalDataAPI, userInfo);
        const data = _get(resp, 'data.usersAdditionalData', {});
        yield put(userActions.getUsersAdditionalData.success(data));
        return data;
    } catch (error) {
        yield put(userActions.getUsersAdditionalData.error(error));
        return error;
    }
}

/**
 * Saga to get user settings data
 * @method getUserSettingsSaga
 * @param  {Object}               action The original redux-action
 * @return {Object|Error}
 */
export function* getUserSettingsSaga(action) {
    try {
        const { payload: userId } = action;
        const resp = yield call(getUserSettingsAPI, userId);
        const data = _get(resp, 'data', {});
        yield put(userActions.getUserSettings.success(data));
        return data;
    } catch (error) {
        yield put(userActions.getUserSettings.error(error));
        return error;
    }
}

/**
 * Saga to update user settings data
 * @method updateUserSettingsSaga
 * @param  {Object}               action The original redux-action
 * @return {Object|Error}
 */
export function* updateUserSettingsSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(updateUserSettingsAPI, payload);
        const data = _get(resp, 'data', {});
        yield put(userActions.updateUserSettings.success(data));
        return data;
    } catch (error) {
        yield put(userActions.updateUserSettings.error(error));
        return error;
    }
}
