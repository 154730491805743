/**
 * Available configuration types
 *
 * @type {{ALTERNATE: string, MASTER: string, ADD_ON: string}}
 */
export const CONFIGURATION_TYPE = {
    ADD_ON: 'add-on',
    ALTERNATE: 'alternate',
    MASTER: 'master',
};

export const DELETE_SKU_MESSAGE = {
    bcselite: `If you remove this Products and Services any BCS document under a company using this configuration will be removed too.`,
    handbooks: `Please note that ALL previously created handbooks will no longer be available if you remove this Products and Services.`,
    handbooksfederal: `Please note that ALL previously created federal handbooks will no longer be available if you remove this Products and Services.`,
    handbooksonestate: `Please note that ALL previously created one-state handbooks will no longer be available if you remove this Products and Services.`,
    handbooksmultistate: `Please note that ALL previously created multi-state handbooks will no longer be available if you remove this Products and Services.`,
    peohandbook: `Please note that ALL previously created PEO handbooks will no longer be available if you remove this Products and Services.`,
    esignature: `Please note that e-Signature data will not be deleted when the e-signature Products and Services is deleted`,
    learncustomcourseuploader: `Please note that ALL previously created custom course(s) will no longer be available if you remove this Products and Services.`,
    handbookslocalpolicies:
        'Please note that ALL previously created Local Policies handbooks will no longer be available if you remove this Products and Services.',
};

export const ERROR_CODE_UPDATE = '1292';

export const PRODUCT_AND_SERVICES = {
    CONFIRMATION_TEXT: `Would you also like to apply the following Products and Services to all alternate configurations
                connected to this master configuration?`,
    PERMISSIONS_CONFIG: `Products and Services Permissions`,
};

export const CONFIGURATION_NOTE_DETAILS = {
    TITLE: 'Important note:',
    CONFIG_NOTE: [
        'It’s not recommended to assign clients the master configuration.',
        'Companies assigned to the master configuration have access to and are billable for all products included in the master configuration.',
        'Assigning your clients to an ALTERNATE_CONFIGURATION is the best practice.',
        'Questions about managing configurations? Visit the HELP_CENTER for videos and other guides.',
    ],
    ALTERNATE_CONFIGURATION: 'alternate configuration',
};

export const ADDON_CONFIG = {
    TYPE: 'checkboxWithRemoveLink',
    HEAD_TYPE: 'removeAllSkus',
    REMOVE: 'remove',
    REMOVE_ALL: 'Remove All',
    CONFIRM_MSG:
        'Removing SKUs can also remove the data for these features. Are you sure you want to proceed?',
    BUTTONS_COLOR: {
        SECONDARY: 'secondary',
        PRIMARY: 'primary',
    },
    BUTTONS_TEXT: {
        CANCEL: 'Cancel',
        OK: 'OK',
    },
};
