const HR_LOGICS = {
    ACA_REPORTING_TOOL_LINK_CLICK: {
        event: 'navigation',
        eventProperties: {
            description: 'User click on app navigation',
            selection: 'hrtools:aca-reporting',
        },
        context: {
            title: 'Mineral Platform',
        },
    },
};

export default HR_LOGICS;
