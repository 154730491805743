import { createActions } from 'utils/actions';

/**
 * Redux actions for the auth login flow.
 *
 * @type {Object}
 */
export const login = createActions('auth', 'LOGIN');

/**
 * Redux actions for the auth logout flow.
 *
 * @type {Object}
 */
export const logout = createActions('auth', 'LOGOUT');

/**
 * Redux actions for the auth internally logout flow.
 *
 * @type {Object}
 */
export const internallyLogout = createActions('auth', 'INTERNALLY_LOGOUT');

/**
 * Redux actions for the auth fetch token flow.
 *
 * @type {Object}
 */
export const fetchToken = createActions('auth', 'FETCH_TOKEN', {
    asPromise: true,
});

/**
 * Redux actions for the auth decode token flow.
 *
 * @type {Object}
 */
export const decodeToken = createActions('auth', 'DECODE_TOKEN', { asPromise: true });

/**
 * Redux actions for the auth refresh token flow.
 *
 * @type {Object}
 */
export const refreshToken = createActions('auth', 'REFRESH_TOKEN', { asPromise: true });

/**
 * Redux actions for the pre-session bootstrap flow.
 *
 * @type {Object}
 */
export const preSessionBootstrap = createActions('auth', 'PRE_SESSION_BOOTSTRAP', {
    asPromise: true,
});

/**
 * Enable display of the "impersonation is starting" banner
 *
 * @type {Object}
 */
export const setImpersonateUserShowStart = createActions('admin', 'IMPERSONATE_USER_SHOW_START', {
    asPromise: true,
});

/**
 * Redux actions for the fetching the imperosnate user token
 *
 * @type {Object}
 */
export const fetchImpersonateUserToken = createActions('admin', 'IMPERSONATE_USER_TOKEN', {
    asPromise: true,
});

/**
 * Redux actions for the fetching the imperosnate user content
 *
 * @type {Object}
 */
export const fetchImpersonateUserContent = createActions('auth', 'IMPERSONATE_USER_CONTENT');

/**
 * Impersonate a user.
 *
 * @type {Object}
 */
export const impersonateUser = createActions('admin', 'IMPERSONATE_USER', { asPromise: true });

/**
 * Enable display of the "impersonation is ending" banner
 *
 * @type {Object}
 */
export const setImpersonateUserShowEnd = createActions('admin', 'IMPERSONATE_USER_SHOW_END', {
    asPromise: true,
});

/**
 * End impersonation of user.
 *
 * @type {Object}
 */
export const endImpersonateUser = createActions('admin', 'END_IMPERSONATE_USER', {
    asPromise: true,
});

/**
 * Set isExpired state
 *
 * @type {Object}
 */
export const setIsExpired = createActions('auth', 'SET_IS_EXPIRED');

/**
 * Set loginAttemptStatus state
 *
 * @type {Object}
 */
export const setLoginAttemptStatus = createActions('auth', 'SET_LOGIN_ATTEMPT_STATUS');

/**
 * Redux actions for the auth login flow.
 *
 * @type {Object}
 */
export const loginUser = createActions('auth', 'LOGIN_USER', {
    asPromise: true,
});

/**
 * Redux actions for the auth contact us flow.
 *
 * @type {Object}
 */
export const contactUsTicket = createActions('auth', 'CONTACT_US_TICKET', {
    asPromise: true,
});

/**
 * Redux action for the custom sso login.
 *
 * @type {Object}
 */
export const customSsoLogin = createActions('auth', 'CUSTOM_SSO_LOGIN');

/**
 * Redux actions for the openIdAuth API.
 *
 * @type {Object}
 */
export const openIdAuth = createActions('auth', 'OPEN_ID_AUTH', {
    asPromise: true,
});

/**
 * Redux actions to get token from code.
 *
 * @type {Object}
 */
export const fetchTokenFromCode = createActions('auth', 'FETCH_TOKEN_FROM_CODE', {
    asPromise: true,
});

/**
 * Redux actions to get token from code.
 *
 * @type {Object}
 */
export const fetchOpenIdIdentityToken = createActions('auth', 'FETCH_OPEN_ID_IDENTITY_TOKEN', {
    asPromise: true,
});

/**
 * Redux actions for the fetchOpenIdConnectDetails.
 *
 * @type {Object}
 */
export const fetchOpenIdConnectDetails = createActions('auth', 'FETCH_OPEN_ID_CONNECT_DETAILS', {
    asPromise: true,
});

/**
 * Redux actions to get token from code.
 *
 * @type {Object}
 */
export const fetchAccessAuthToken = createActions('auth', 'FETCH_ACCESS_AUTH_TOKEN', {
    asPromise: true,
});

/**
 * Redux actions to close Aries Session.
 *
 * @type {Object}
 */
export const closeAriesSession = createActions('auth', 'CLOSE_ARIES_SESSION', {
    asPromise: true,
});
