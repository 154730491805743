import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import queryString from 'query-string';
import { all, call, put, race, select, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import RavenError from 'ravenjs/lib/Error';

import MESSAGES from 'constants/messages';
import { roleHasPermission } from 'utils/roles';
import { logoutAPI, logoutFromVendorsAPI, openIdAuth } from 'modules/apis';
import * as userSelectors from 'modules/user/reducer';
import {
    ROOT_PRIVATE_ROUTE,
    ROOT_PRIVATE_ROUTE_PLATFORM_LITE,
    ROOT_PUBLIC_ROUTE,
} from 'constants/routes';
import { MODALS } from 'constants/modals';
import { catchError } from 'utils/errors';
import { fetchAppBootstrapSaga } from 'modules/bootstrap/sagas';
import * as brandingSelectors from 'modules/branding/reducer';
import { fetchBrandingSaga } from 'modules/branding/sagas';
import { selectors as routerSelectors } from 'modules/router';
import { actions as uiActions } from 'modules/ui';
import { getChatSessionId } from 'utils/common';
import { initializePendo } from 'utils/pendo';
import { closeAllAISessionAPI } from 'modules/apis/aiAssistant';
import * as authActions from '../actions';
import * as authSelectors from '../reducer';
import { decodeTokenSaga, fetchTokenSaga } from './token';
import { closeAriesSessionSaga } from './ariesChat';
import { buildUserPermissionsSaga } from '../../user/sagas/permissions';

/**
 * Pre-session bootstrap saga
 *
 * @param  {Object}     action
 * @method preSessionBootstrapSaga
 */
export function* preSessionBootstrapSaga(action = {}) {
    try {
        const { payload: searchKey } = action;
        const savedBranding = yield select(brandingSelectors.getBranding);
        if (searchKey && savedBranding.isDefault) {
            yield all([call(fetchBrandingSaga, { payload: searchKey })]);
        }
        yield put(authActions.preSessionBootstrap.success());
    } catch (e) {
        yield put(authActions.preSessionBootstrap.error(e));
    }
}

/**
 * Log the user in using the given user credentials.
 *
 * NOTE: private method exported for testing purposes.
 *
 * @method _loginUserSaga
 * @private
 * @type   {Generator}
 * @param  {Object}     userCreds The given user credentials
 */
export function* _loginUserSaga(userCreds) {
    // Try to fetch the token and log the user in.
    try {
        // Fetch the token based on the given user credentials.
        const token = yield call(fetchTokenSaga, { payload: userCreds });
        // Catch an error, if any.
        catchError(token);
        // Extract the token from app state.
        const accessToken = yield select(authSelectors.getAccessToken);
        // TODO: make this DRY by leveraging _loginWithAccessTokenSaga() ???
        // Decode the token to get some info about the user.
        const decodedToken = yield call(decodeTokenSaga, { payload: accessToken });
        // Catch an error, if any.
        catchError(decodedToken);
        // openID auth if username and password are present.
        openIdAuth(userCreds);

        yield call(fetchAppBootstrapSaga);

        // Build the user's permissions.
        yield call(buildUserPermissionsSaga);

        const userId = yield select(userSelectors.getUserId);
        const companyId = yield select(userSelectors.getCompanyId);
        const configurationId = yield select(userSelectors.getConfigurationId);
        // If we have a valid `access_token`, then let's fire the `loginSuccess` action.
        // Otherwise, we'll throw an error for no valid `access_token` present.
        if (accessToken && userId && companyId && configurationId) {
            yield put(authActions.login.success());
        } else {
            catchError(new RavenError('Sign in failure.'));
        }
    } catch (err) {
        // Otherwise catch the error and fire the `loginError` action.
        yield put(authActions.login.error(err));
    }
}

/**
 * Log the user out.
 *
 * NOTE: private method exported for testing purposes.
 *
 * @method _logoutUserSaga
 * @private
 * @type   {Generator}
 */
export function* _logoutUserSaga() {
    try {
        const location = yield select(routerSelectors.getLocation);
        const { from, partnerLoginUrl } = queryString.parse(!_isEmpty(location) && location.search);
        yield put(push('/auth/end-user-session', { from, partnerLoginUrl }));
        yield put(authActions.logout.success());
    } catch (e) {
        yield put(authActions.logout.error(e));
    }
}

/**
 * The saga for the login flow.
 *
 * @method loginSaga
 * @type   {Generator}
 * @param  {Object}    action The login action containing the user creds
 */
export function* loginSaga(action) {
    // We're listening to the `LOGIN` action to TRIGGER.
    // The `LOGIN` action will have the user's credentials as payload.
    const { payload: userCreds } = action;
    const { rememberUsername, ...rest } = userCreds;
    // A user might call the `LOGOUT` action while we're running the `loginUser` saga.
    // THis is unlikely, but in an event where this occurs, we'll write a race condition.
    // The `race` returns the action call which won the race...straightforward no?
    const { logout, success } = yield race({
        // Pass in the `userCreds` to the `_loginUser` saga.
        callLogin: call(_loginUserSaga, rest),
        // Watch for the `login.SUCCESS` action.
        success: take(authActions.login.SUCCESS),
        // Watch for the `login.ERROR` action.
        error: take(authActions.login.ERROR),
        // `logout` saga doesn't require any payload, yet.
        logout: take(authActions.logout.TRIGGER),
    });
    yield put(uiActions.isLoading(false));
    // If the `login` saga won the race, then fire up some actions...
    if (success) {
        yield put(uiActions.closeModal(MODALS.LOGIN));
        // Close Aries Session
        const userId = yield select(userSelectors.getUserId);
        yield call(_closeAllAISessionSaga, userId);
        // Get the current location from redux.
        const location = yield select(routerSelectors.getLocation);
        // Determine if we have a `from` search parameter
        const { from } = queryString.parse(!_isEmpty(location) && location.search);
        const { redirect, redirectState } = userCreds;
        // If we have a `from` param, and it starts with a forward slash,
        // Redirect the user to the route they came from.
        if (from && from.startsWith('/')) {
            yield put(push(from));
            // We don't want to continue with the saga.
            return;
        }
        // If we have a `redirect` param from custom SSO login credentials, redirect user
        if (redirect && redirect.startsWith('/')) {
            if (rest?.reload) window.location.reload();
            yield put(push(redirect, redirectState));
            // We don't want to continue with the saga.
            return;
        }
        if (rememberUsername) {
            const { username } = rest;
            localStorage.setItem('remember_username', username);
        } else {
            localStorage.removeItem('remember_username');
        }
        // Otherwise, push the user to the root `private_route`.
        const permissions = yield select(userSelectors.getUserPermissionsOriginal);

        const isPlatformLiteUser = roleHasPermission(
            { permissions },
            'platformlite.insightsCompanydashboard.view'
        );

        const isInsightsUser =
            roleHasPermission({ permissions }, 'mnlintelligence.insights.view') &&
            roleHasPermission({ permissions }, 'mnlintelligence.insights.all');
        if (isPlatformLiteUser && isInsightsUser) {
            yield put(push(ROOT_PRIVATE_ROUTE_PLATFORM_LITE));
        } else {
            yield put(push(ROOT_PRIVATE_ROUTE));
        }
    } else if (logout) {
        yield put(uiActions.closeModal(MODALS.LOGIN));
        // If the user is trying to logout,
        // push them to the root 'public_route'.
        yield put(push(ROOT_PUBLIC_ROUTE));
    }
}

/**
 * The saga for the logout flow.
 *
 * @method logoutSaga
 * @param {Object} action
 * @type   {Generator}
 */
export function* logoutSaga(action) {
    let loginUrl = null;
    const goto = _get(action, 'payload.goto', null);
    const chatSessionId = getChatSessionId();
    try {
        yield put(uiActions.isLoading(true, MESSAGES.LOADING.LOGGING_OUT));
        const response = yield call(logoutAPI);
        loginUrl = _get(response, 'data.loginUrl', null);
    } catch (e) {
        yield put(authActions.logout.error(e));
    } finally {
        if (!_isEmpty(chatSessionId)) {
            yield call(closeAriesSessionSaga);
        }
        if (goto) {
            yield put(authActions.logout.success());
            yield put(push(goto));
        } else {
            yield call(_logoutUserSaga, loginUrl);
        }
        yield call(initializePendo, { clearUser: true });
        yield put(uiActions.isLoading(false));
    }
}

/**
 * The saga for the internally logout flow.
 *
 * @method internallyLogoutSaga
 * @param {Object} action
 * @type   {Generator}
 */
export function* internallyLogoutSaga(action) {
    const chatSessionId = getChatSessionId();
    try {
        if (!_isEmpty(chatSessionId)) {
            yield call(closeAriesSessionSaga);
        }
        yield call(logoutAPI);
    } catch (e) {
        yield put(authActions.logout.error(e));
    } finally {
        yield put(authActions.internallyLogout.success());
        yield call(initializePendo, { clearUser: true });
        yield put(uiActions.isLoading(false));
    }
}

export function* logoutFromVendorSaga({ logoutUrl }) {
    try {
        if (logoutUrl) {
            yield call(logoutFromVendorsAPI, logoutUrl);
        }
        return true;
    } catch (e) {
        return false;
    }
}

/**
 * The saga to enable display of the "impersonation is starting" banner
 *
 * @method setImpersonateUserShowStartSaga
 * @type   {Generator}
 * @return {boolean|Error}
 */
export function* setImpersonateUserShowStartSaga() {
    try {
        yield put(authActions.setImpersonateUserShowStart.success());
    } catch (error) {
        yield put(authActions.setImpersonateUserShowStart.error(error));

        return error;
    }

    return true;
}

/**
 * Log the user in using the given access token.
 *
 * @method _loginWithAccessTokenSaga
 * @private
 * @type   {Generator}
 * @param  {string}  accessToken
 * @return {Object|Error}
 */
export function* _loginWithAccessTokenSaga(accessToken) {
    try {
        openIdAuth({ accessToken });
        const decodedToken = yield call(decodeTokenSaga, { payload: accessToken });
        catchError(decodedToken);

        yield call(fetchAppBootstrapSaga);
        yield call(buildUserPermissionsSaga);

        const userId = yield select(userSelectors.getUserId);
        const companyId = yield select(userSelectors.getCompanyId);
        const configurationId = yield select(userSelectors.getConfigurationId);

        if (accessToken && userId && companyId && configurationId) {
            yield put(authActions.login.success(decodedToken));
        } else {
            catchError(new RavenError('Sign in failure.'));
        }
    } catch (err) {
        yield put(authActions.login.error(err));

        return err;
    }
    return {};
}

/**
 * The saga to enable display of the "impersonation is ending" banner
 *
 * @method setImpersonateUserShowEndSaga
 * @type   {Generator}
 * @return {boolean|Error}
 */
export function* setImpersonateUserShowEndSaga() {
    try {
        yield put(authActions.setImpersonateUserShowEnd.success());
    } catch (error) {
        yield put(authActions.setImpersonateUserShowEnd.error(error));

        return error;
    }

    return true;
}

/**
 * Close All AI Session.
 *
 * @method _closeAllAISessionSaga
 * @private
 * @param  {string}  userId
 * @type   {Generator}
 * @return {boolean|Error}
 */
export function* _closeAllAISessionSaga(userId) {
    const chatSessionId = getChatSessionId();
    try {
        if (!_isEmpty(chatSessionId)) {
            localStorage.removeItem('ai_chat_session_active');
        }
        if (!_isEmpty(JSON.parse(localStorage.getItem('ai_chat_session')))) {
            localStorage.removeItem('ai_chat_session');
        }
        if (userId) {
            yield call(closeAllAISessionAPI, userId);
        }
        return true;
    } catch (e) {
        return false;
    }
}
