import { takeLatest } from 'redux-saga/effects';

import * as authActions from '../actions';
import {
    contactUsTicketSaga,
    customSsoLoginSaga,
    endImpersonateUserSaga,
    fetchAccessAuthTokenSaga,
    fetchImpersonateUserContentSaga,
    fetchImpersonateUserTokenSaga,
    fetchOpenIdConnectDetailsSaga,
    fetchOpenIdIdentityTokenSaga,
    fetchTokenFromCodeSaga,
    impersonateUserSaga,
    openIdAuthSaga,
    setIsExpiredSaga,
    setLoginAttemptStatusSaga,
} from './auth';
import {
    internallyLogoutSaga,
    loginSaga,
    logoutSaga,
    preSessionBootstrapSaga,
    setImpersonateUserShowEndSaga,
    setImpersonateUserShowStartSaga,
} from './login';
import { decodeTokenSaga, fetchTokenSaga, refreshTokenSaga } from './token';
import { closeAriesSessionSaga } from './ariesChat';

/**
 * The root `auth` saga.
 * We're triggering sagas based their respective events.
 *
 * @method authSaga
 * @type   {Generator}
 */
function* authSaga() {
    // End user impersonation
    yield takeLatest(authActions.endImpersonateUser.TRIGGER, endImpersonateUserSaga);
    // fetch Impersonate User Token
    yield takeLatest(authActions.fetchImpersonateUserToken.TRIGGER, fetchImpersonateUserTokenSaga);
    // Login the user
    yield takeLatest(authActions.login.TRIGGER, loginSaga);
    // Logout the user
    yield takeLatest(authActions.logout.TRIGGER, logoutSaga);
    yield takeLatest(authActions.internallyLogout.TRIGGER, internallyLogoutSaga);
    // Fetch the token
    yield takeLatest(authActions.fetchToken.TRIGGER, fetchTokenSaga);
    // Decode the token
    yield takeLatest(authActions.decodeToken.TRIGGER, decodeTokenSaga);
    // Pre-session bootstrap
    yield takeLatest(authActions.preSessionBootstrap.TRIGGER, preSessionBootstrapSaga);
    // Refresh the token
    yield takeLatest(authActions.refreshToken.TRIGGER, refreshTokenSaga);
    // Enable display of the "impersonation is ending" banner
    yield takeLatest(authActions.setImpersonateUserShowEnd.TRIGGER, setImpersonateUserShowEndSaga);
    // Enable display of the "impersonation is starting" banner
    yield takeLatest(
        authActions.setImpersonateUserShowStart.TRIGGER,
        setImpersonateUserShowStartSaga
    );
    // fetch impersonate user content
    yield takeLatest(
        authActions.fetchImpersonateUserContent.TRIGGER,
        fetchImpersonateUserContentSaga
    );
    yield takeLatest(authActions.setIsExpired.TRIGGER, setIsExpiredSaga);
    yield takeLatest(authActions.setLoginAttemptStatus.TRIGGER, setLoginAttemptStatusSaga);
    yield takeLatest(authActions.impersonateUser.TRIGGER, impersonateUserSaga);
    yield takeLatest(authActions.loginUser.TRIGGER, loginSaga);
    yield takeLatest(authActions.contactUsTicket.TRIGGER, contactUsTicketSaga);
    yield takeLatest(authActions.customSsoLogin.TRIGGER, customSsoLoginSaga);
    yield takeLatest(authActions.openIdAuth.TRIGGER, openIdAuthSaga);
    yield takeLatest(authActions.fetchTokenFromCode.TRIGGER, fetchTokenFromCodeSaga);
    yield takeLatest(authActions.fetchOpenIdIdentityToken.TRIGGER, fetchOpenIdIdentityTokenSaga);
    yield takeLatest(authActions.fetchOpenIdConnectDetails.TRIGGER, fetchOpenIdConnectDetailsSaga);
    yield takeLatest(authActions.fetchAccessAuthToken.TRIGGER, fetchAccessAuthTokenSaga);
    yield takeLatest(authActions.closeAriesSession.TRIGGER, closeAriesSessionSaga);
}

// Default export is the module's root saga.
export default authSaga;

// Named exports are all the internal sagas that can be used
// for testing as well as imports in other modules
export {
    endImpersonateUserSaga,
    loginSaga,
    logoutSaga,
    setImpersonateUserShowEndSaga,
    setImpersonateUserShowStartSaga,
    fetchImpersonateUserContentSaga,
    fetchImpersonateUserTokenSaga,
    setIsExpiredSaga,
    setLoginAttemptStatusSaga,
    fetchOpenIdConnectDetailsSaga,
    fetchAccessAuthTokenSaga,
    closeAriesSessionSaga,
};
